import { useUserStore } from "@/stores/user/User";
import { isEmpty } from "lodash";

export const getLangMapItem = (langMap?: { [key: string]: string }) => {
  if (!langMap) return null;

  const userStore = useUserStore();
  let langString =
    userStore.loggedUserInfo.locale || userStore.organization.locale;

  if (!langMap[langString]) {
    langString = "";
    Object.keys(langMap).forEach((key) => {
      if (isEmpty(langString)) {
        langString = key;
      }
    });
  }

  return langMap[langString] || null;
};

export const getLangString = () => {
  const userStore = useUserStore();

  return userStore.loggedUserInfo.locale || userStore.organization.locale;
};
