<script setup lang="ts">
import { useIcons } from "@/composables/frontend/Icons";
import { setOpt } from "@/lib/Object";
import { computed } from "vue";

const props = defineProps<{
  slug: string | undefined;
  size?: number;
  width?: number;
  height?: number;
  strokeWidth?: number;
  color?: string;
}>();

const icons = useIcons();
const iconComponent = computed(() => {
  if (!props.slug) {
    return undefined;
  }

  if (props.slug in icons.value) {
    return icons.value[props.slug];
  }

  if (props.slug.startsWith("file-")) {
    return icons.value["file-alt"];
  }

  return undefined;
});
</script>

<template>
  <component
    v-if="slug"
    :is="iconComponent"
    v-bind="{
      ...setOpt('size', size),
      ...setOpt('width', width),
      ...setOpt('height', height),
      ...setOpt('color', color),
    }"
  />
</template>
