<script setup lang="ts">
import IconChevronDown from "./IconChevronDown.vue";

const emit = defineEmits(["update:open"]);
withDefaults(defineProps<{
  width?: number;
  height?: number;
  color?: string;
  open?: boolean;
}>(), {
  width: 24,
  height: 24,
  color: "var(--enlivy-primary-color)",
  open: false,
});

function updateValue(newValue: boolean) {
  emit("update:open", newValue);
}
</script>

<template>
  <div class="arrow" @click.prevent="updateValue(!open)">
    <IconChevronDown
      :class="{ open: open }"
      :color="color"
      :width="width"
      :height="height"
    />
  </div>
</template>

<style scoped lang="scss">
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    transition: transform 0.15s cubic-bezier(1, 0.5, 0.8, 1);
    transition-timing-function: cubic-bezier(1, 0.5, 0.8, 1);

    &.open {
      transform: rotate(180deg);
    }
  }
}
</style>
