import { useOrganizationPayslipSchemaApi } from "@/api/organization/OrganizationPayslipSchemaApi";
import { getLangMapItem } from "@/composables/frontend/GetLangMapItem";
import type { OrganizationPayslipSchema } from "@/interfaces/organization/payslips/OrganizationPayslipSchema";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { isEmpty } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { useUserStore } from "@/stores/user/User";
import { defineStore } from "pinia";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationPayslipSchemasStore = defineStore(
  "organizationPayslipSchemas",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationPayslipSchema>();
    const pageCache = new OrganizationCache<OrganizationPayslipSchema>();

    const genericActions =
      new OrganizationGenericActions<OrganizationPayslipSchema>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationPayslipSchemaApi(),
        ignoredKeysForDeletion: [
          "_entity",
          "id",
          "organization_id",
          "field_map",
          "field_group_list",
        ],
        enhanceEntity: enhanceEntity,
        prePersist: prePersist,
      });

    return {
      ...genericActions,
    };
  }
);

function enhanceEntity(
  entity: OrganizationPayslipSchema,
  storage: OrganizationEntityStorage<OrganizationPayslipSchema>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const organizationUserStore = useOrganizationUsersStore();
  const userStore = useUserStore();
  addComputedField<OrganizationPayslipSchema, OrganizationUser>(
    entity,
    "deletedByUser",
    () => storage.get(entityIdentifier)?.deleted_by_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.deleted_by_user_id,
      })
  );

  const organizationLanguage = userStore.organization.locale;
  setEntityNameFields(entity, () =>
    getLangMapItem(storage.get(entityIdentifier)?.name_lang_map)
  );
}

function prePersist(
  entity: OrganizationPayslipSchema,
  existingEntity?: OrganizationPayslipSchema
) {
  // If it's an update operation, mark sub-entities removed for deletion.
  if (existingEntity) {
    const remainingEntityLanguages =
      entity.name_lang_map && !isEmpty(entity.name_lang_map)
        ? Object.keys(entity.name_lang_map)
        : [];

    for (const lang in existingEntity.name_lang_map) {
      if (!remainingEntityLanguages.includes(lang)) {
        if (!entity.name_lang_map) {
          entity.name_lang_map = {};
          entity.description_lang_map = {};
        }

        entity.name_lang_map[lang] = "";
        entity.description_lang_map[lang] = "";
      }
    }
  }
}
