import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { BaseApi } from "@/api/generic/BaseApi";
import { ApiConverter } from "@/api/mapping/ApiConverter";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { OrganizationUserRole } from "@/interfaces/organization/user/OrganizationUserRole";
import type { OrganizationUserRoleAbility } from "@/interfaces/organization/user/OrganizationUserRoleAbility";
import axios from "axios";

class OrganizationUserRoleAbilityApi extends BaseApi {
  apiIdentifier = "organization_user_role_ability";

  getBaseUrl(args: { organizationId: string; userRoleId: string }) {
    return `/organizations/${args.organizationId}/user-roles/${args.userRoleId}/abilities`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(
      entityFromApi,
      "organization_user_role_ability"
    )
      .asOrganizationEntity()
      .asId("organization_user_role_id")
      .build() as OrganizationUserRoleAbility;
  }

  async get(args: {
    userRole: OrganizationUserRole;
  }): Promise<OrganizationUserRoleAbility[]> {
    const { userRole } = args;

    return await this.baseApiGetEntities({
      url: this.getBaseUrl({
        organizationId: userRole.organization_id,
        userRoleId: userRole.id,
      }),
    });
  }

  async post(args: { userRole: OrganizationUserRole; abilities: string[] }) {
    const { userRole, abilities } = args;

    return this.baseApiPostEntity({
      url: this.getBaseUrl({
        organizationId: userRole.organization_id,
        userRoleId: userRole.id,
      }),
      entity: { abilities },
      parseEntity: this.parseEntity,
    });
  }

  async delete(args: { userRole: OrganizationUserRole; abilityId: string }) {
    const { userRole, abilityId } = args;

    return this.baseApiDeleteEntity({
      url: this.getBaseUrl({
        organizationId: userRole.organization_id,
        userRoleId: userRole.id,
      }),
      entityId: abilityId,
      parseEntity: this.parseEntity,
    });
  }

  async deleteArray(args: {
    userRole: OrganizationUserRole;
    abilityList: string[];
  }) {
    const { userRole, abilityList } = args;
    const url = this.getBaseUrl({
      organizationId: userRole.organization_id,
      userRoleId: userRole.id,
    });

    try {
      const headers = authHeader();

      const request = ApiConverter.toRequestData(
        { abilities: abilityList },
        new URLSearchParams()
      );
      const joinerString = url.includes("?") ? "&" : "?";
      const response = await axios.post(
        `${url}${joinerString}_method=DELETE`,
        request,
        {
          headers,
        }
      );

      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationUserRoleAbilityApi() {
  return new OrganizationUserRoleAbilityApi();
}
