export const getSearchRoute = (entity: string, id: string) => {
  const route = routesMap.find((route) => route.entity === entity);

  if (route) {
    return {
      name: route.name,
      params: {
        [route.id_slug]: id,
      },
    };
  } else {
    return "";
  }
};

export const routesMap = [
  {
    entity: "organization_users",
    id_slug: "organizationUserId",
    name: "viewOrganizationUser",
  },
  {
    entity: "organization_billing_schedules",
    id_slug: "organizationBillingScheduleId",
    name: "viewOrganizationBillingSchedule",
  },
  {
    entity: "organization_invoices",
    id_slug: "organizationInvoiceId",
    name: "viewOrganizationInvoice",
  },
  {
    entity: "organization_network_exchanges",
    id_slug: "organizationInvoiceNetworkExchangeId",
    name: "viewOrganizationInvoiceNetworkExchange",
  },
  {
    entity: "organization_receipts",
    id_slug: "organizationReceiptId",
    name: "viewOrganizationReceipt",
  },
  {
    entity: "organization_bank_accounts",
    id_slug: "organizationBankAccountId",
    name: "viewOrganizationBankAccount",
  },
  {
    entity: "organization_bank_transactions",
    id_slug: "organizationBankTransactionId",
    name: "viewOrganizationBankTransaction",
  },
  {
    entity: "organization_products",
    id_slug: "organizationProductId",
    name: "viewOrganizationProduct",
  },
  {
    entity: "organization_tasks",
    id_slug: "organizationTaskId",
    name: "viewOrganizationTask",
  },
  {
    entity: "organization_projects",
    id_slug: "organizationProjectId",
    name: "viewOrganizationProject",
  },
  {
    entity: "organization_contracts",
    id_slug: "organizationContractId",
    name: "viewOrganizationContract",
  },
  {
    entity: "organization_playbooks",
    id_slug: "organizationPlaybookId",
    name: "viewOrganizationPlaybook",
  },
  {
    entity: "organization_payslips",
    id_slug: "organizationPayslipId",
    name: "editOrganizationPayslip",
  },
];
