<script setup lang="ts">
import type { SelectOption } from "@/interfaces/common/SelectOption";
import { useFrontendStore } from "@/stores/Frontend";
import { computed } from "vue";
import Select from "./Select.vue";

const emit = defineEmits(["update:modelValue"]);
const props = withDefaults(
  defineProps<{
    id: string;
    label?: string;
    description?: string;
    tooltip?: string;
    modelValue?: string | number | (string | number)[];
    disabled?: boolean;
    options?: SelectOption[];
  }>(),
  {
    disabled: false,
    options: () => [],
  }
);

const frontend = useFrontendStore();

const languageSelectOptions = computed(() => {
  const selectOptions = [] as SelectOption[];

  if (
    props.modelValue == undefined ||
    (Array.isArray(props.modelValue) && props.modelValue.length == 0)
  ) {
    selectOptions.push({
      id: undefined,
      label: frontend.trans("general.operation.select")!,
    });
  }

  if (props.options.length != 0) {
    for (const option of props.options) {
      selectOptions.push({
        ...option,
        ...getFlag(option.id as string),
      });
    }
  } else {
    const frontend = useFrontendStore();
    for (const locale in frontend.locales) {
      selectOptions.push({
        id: locale,
        label: locale.toUpperCase(),
        ...getFlag(locale),
      });
    }
  }

  return selectOptions;
});

function getFlag(locale: string) {
  if (!frontend.locale_to_country_code_iso[locale]) {
    return { icon: "/src/assets/icons/IconWorld.png" };
  }

  return {
    iconClass: `fi fi-${frontend.locale_to_country_code_iso[
      locale
    ].toLowerCase()}`,
  };
}
</script>

<template>
  <Select
    :id="id"
    :label="label"
    :description="description"
    :tooltip="tooltip"
    :modelValue="modelValue"
    :disabled="disabled"
    :isClearable="false"
    @update:modelValue="emit('update:modelValue', $event)"
    :options="languageSelectOptions"
    background-selected-color="var(--enlivy-white-100-color)"
    v-bind="$attrs"
  />
</template>
