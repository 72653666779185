<script setup lang="ts">
import Button from "@/components/elements/buttons/Button.vue";
import { useFrontendStore } from "@/stores/Frontend";
import { closeDialog } from "vue3-promise-dialog";
import BaseDialog from "./BaseDialog.vue";

defineProps<{
  title: string;
  text: string;
}>();

const frontend = useFrontendStore();
</script>

<template>
  <BaseDialog :title="title" @close="closeDialog(false)" width="500px">
    <div class="html-content">
      {{ text }}
    </div>

    <template #actions>
      <Button class="secondary" @clicked="closeDialog(true)">
        {{ frontend.trans("general.operation.close") }}
      </Button>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.html-content {
  @include content-from-editor();
}
</style>
