import { useOrganizationTaxClassApi } from "@/api/organization/OrganizationTaxClassApi";
import { getLangMapItem } from "@/composables/frontend/GetLangMapItem";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationTaxClass } from "@/interfaces/organization/tax/tax-classes/OrganizationTaxClass";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { useGenericPusherUtils } from "@/stores/generic/GenericPusherUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { useUserStore } from "@/stores/user/User";
import { defineStore } from "pinia";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationTaxClassesStore = defineStore(
  "organizationTaxClasses",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationTaxClass>();
    const pageCache = new OrganizationCache<OrganizationTaxClass>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationTaxClass>(
        "tax_class",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationTaxClass>(
      {
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationTaxClassApi(),
        enhanceEntity: enhanceEntity,
        initializationCallback: subscribeToSocket,
      }
    );

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationTaxClass,
  storage: OrganizationEntityStorage<OrganizationTaxClass>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const organizationUserStore = useOrganizationUsersStore();
  const userStore = useUserStore();
  addComputedField<OrganizationTaxClass, OrganizationUser>(
    entity,
    "deletedByUser",
    () => storage.get(entityIdentifier)?.deleted_by_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.deleted_by_user_id,
      })
  );

  setEntityNameFields(entity, () =>
    getLangMapItem(storage.get(entityIdentifier)?.name_lang_map)
  );
}
