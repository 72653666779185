<script setup lang="ts">
import GlobalSearch from "@/components/lists/filters/GlobalSearch.vue";
import { useFrontendStore } from "@/stores/Frontend";
import { useUserStore } from "@/stores/user/User";
import { nextTick, ref } from "vue";
import LocaleSelector from "./LocaleSelector.vue";
import MobileNav from "./MobileNav.vue";
import SearchIcon from "./SearchIcon.vue";
import ThemeSelector from "./ThemeSelector.vue";
import UserNav from "./UserNav.vue";
import UserProfile from "./UserProfile.vue";

defineProps<{
  sticky: boolean;
  sidebarOpen?: boolean;
}>();

const userStore = useUserStore();
const frontend = useFrontendStore();

const mobileMenuVisible = ref(false);
const mobileSearchVisible = ref(false);

const onMobileMenuToggled = (menuVisible: boolean) => {
  mobileMenuVisible.value = menuVisible;
}

const onMobileSearchToggled = (searchVisible: boolean) => {
  mobileSearchVisible.value = searchVisible;

  nextTick(() => {
    if (searchVisible) {
      const inputElement = document.querySelector("#global-search-input-wrapper input") as HTMLInputElement;
      inputElement?.focus();
    }
  });
}
</script>

<template>
  <header
    :class="{
      'open-menu': mobileMenuVisible && frontend.windowWidth < frontend.deviceBreakpoints.landscapeTablet,
      'open-search': mobileSearchVisible && frontend.windowWidth < frontend.deviceBreakpoints.landscapeTablet,
      'sidebar-open': sidebarOpen,
      sticky: sticky,
    }"
  >
    <div class="w-100">
      <RouterLink
        v-if="userStore.isAuthenticated && mobileMenuVisible && frontend.windowWidth < frontend.deviceBreakpoints.landscapeTablet"
        :to="{ name: 'accountOrganizationsDashboard' }"
      >
        <img class="logo" src="@/assets/logo.png" />
      </RouterLink>
      <GlobalSearch
        v-if="(mobileSearchVisible || frontend.windowWidth > frontend.deviceBreakpoints.landscapeTablet) && userStore.isAuthenticated"
        @closeSearch="onMobileSearchToggled(false)"
      />
    </div>

    <div class="actions">
      <Suspense>
        <UserNav
          v-if="
            userStore.isAuthenticated &&
            frontend.windowWidth >= frontend.deviceBreakpoints.landscapeTablet
          "
        />
      </Suspense>

      <LocaleSelector v-if="!userStore.isAuthenticated" />

      <ThemeSelector v-show="!mobileMenuVisible" />

      <UserProfile
        v-if="userStore.isAuthenticated"
        v-show="!mobileMenuVisible"
      />

      <Suspense>
        <MobileNav
          v-if="
            userStore.isAuthenticated &&
            frontend.windowWidth < frontend.deviceBreakpoints.landscapeTablet
          "
          @menuToggled="onMobileMenuToggled"
        />
      </Suspense>
      <SearchIcon
        v-if="!mobileMenuVisible && userStore.isAuthenticated &&
            frontend.windowWidth < frontend.deviceBreakpoints.landscapeTablet"
        @click="onMobileSearchToggled"
      />
    </div>
  </header>
  <div v-if="sticky" class="header-placeholder"></div>
</template>

<style scoped lang="scss">
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  width: 100%;
  background: var(--enlivy-background-100-color);
  z-index: 11;

  &.open-menu {
    background-color: var(--enlivy-white-100-color);
    z-index: 15;
  }
  &.open-search {
    z-index: 15;
  }
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
  }

  &:not(.open-menu) {
    padding-left: 87px;
  }
}

.header-placeholder {
  height: calc(35px + 2 * var(--enlivy-spacing-divider-sm));
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--enlivy-spacing-divider-md);
  @media only screen and (max-width: $breakpoint-portrait-tablet) {
    gap: var(--enlivy-spacing-divider-xs);
  }
}

.logo {
  max-height: 24px;
}
.small-logo {
  max-height: 32px;
}

@media only screen and (max-width: $breakpoint-portrait-tablet) {
  .global-search-wrapper {
    margin-right: var(--enlivy-spacing-divider-xs);
  }
}

@media only screen and (min-width: $breakpoint-portrait-tablet) {
  header {
    padding: 18px
      calc(var(--enlivy-spacing-divider-sm) * 2);
  }

  header {
    &:not(.open-menu) {
      padding-left: 87px;
    }
  }
}

@media only screen and (min-width: $breakpoint-desktop) {
  header {
    padding: 18px 50px;
  }

  header {
    padding-left: 87px;
    &.sidebar-open {
      &:not(.open-menu) {
        padding-left: 272px;
      }
    }
  }
}
</style>
