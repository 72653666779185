<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(defineProps<{
  size?: number;
  width?: number;
}>(), {
  size: 48,
  width: 5
});

const sizeInPx = computed(() => {
  return `${props.size}px`;
});

const widthInPx = computed(() => {
  return `${props.width}px`;
});
</script>

<template>
  <div class="wrapper">
    <span class="loader"></span>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: v-bind(sizeInPx);
  height: v-bind(sizeInPx);
}

.loader {
  width: v-bind(sizeInPx);
  height: v-bind(sizeInPx);
  border: v-bind(widthInPx) solid var(--enlivy-white-100-color);
  border-bottom-color: var(--enlivy-primary-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
