import { ApiConverter } from "@/api/mapping/ApiConverter";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { Navigation } from "@/interfaces/common/Navigation";
import type { NavigationByState } from "@/interfaces/common/NavigationByState";
import type { Pagination } from "@/interfaces/common/Pagination";
import axios from "axios";
import { getAbortController } from "./AbortControllerMapping";
import { handleApiError } from "./ApiErrorHandler";

// Prefix all methods in this file with baseApi
export abstract class BaseApi {
  abstract apiIdentifier: string;

  baseApiCancelExistingRequests() {
    const abortController = getAbortController(this.apiIdentifier);
    abortController.abort();
  }

  async baseApiGetEntities(args: {
    url: string;
    params?: any;
    parseEntity?: (entity: any) => any;
    criticalFetch?: boolean;
    allowMissing?: boolean;
  }) {
    const { url, params, parseEntity } = args;

    if (params && "q_in" in params && !("q" in params)) {
      delete params["q_in"];
    }

    try {
      const response = (
        await axios.get(url, {
          params: ApiConverter.toApiSpec(params),
          headers: authHeader(),
          signal: getAbortController(this.apiIdentifier).signal,
        })
      ).data;

      if (parseEntity) {
        const entities = [];
        for (const entity of response.data) {
          entities.push(parseEntity(entity));
        }

        response.data = entities;
      }

      return response;
    } catch (error) {
      handleApiError(error, args?.criticalFetch, args?.allowMissing);
    }
  }

  async baseApiGetEntitiesPaginated({
    url,
    page = 1,
    limit = 50,
    deleted = 0,
    params,
    parseEntity,
  }: {
    url: string;
    page?: number;
    limit?: number;
    deleted?: number;
    params?: any;
    parseEntity: (entity: any) => any;
  }) {
    const response = await this.baseApiGetEntities({
      url,
      params: {
        page,
        limit,
        deleted: deleted ? deleted : undefined,
        ...params,
      },
      parseEntity,
    });

    return {
      entities: response.data,
      pagination: (response.meta?.pagination
        ? response.meta.pagination
        : {}) as Pagination,
      navigation: (response.meta?.navigation
        ? response.meta.navigation
        : {}) as Navigation,
      navigationByState: (response.meta?.navigation_by_state
        ? response.meta.navigation_by_state
        : {}) as NavigationByState,
    };
  }

  async baseApiGetEntityById(args: {
    url: string;
    id: string;
    include?: string;
    parseEntity?: (entity: any) => any;
    criticalFetch?: boolean;
    allowMissing?: boolean;
  }) {
    const { url, id, include, parseEntity } = args;

    const getUrl = `${url}/${id}` + (include ? `?include=${include}` : "");
    try {
      const response = await axios.get(getUrl, {
        headers: authHeader(),
        signal: getAbortController(this.apiIdentifier).signal,
      });

      let entity = response.data.data;
      if (parseEntity) {
        entity = parseEntity(entity);
      }

      return entity;
    } catch (error: any) {
      handleApiError(error, args.criticalFetch, args.allowMissing);
    }
  }

  async baseApiGet({
    allowSoftFail = false,
    ...args
  }: {
    url: string;
    parseEntity?: (entity: any) => any;
    allowSoftFail?: boolean;
  }) {
    const { url, parseEntity } = args;

    try {
      const response = await axios.get(url, {
        headers: authHeader(),
        signal: getAbortController(this.apiIdentifier).signal,
      });

      let entity = response.data;
      if ("data" in entity) {
        entity = entity.data;
      }
      if (parseEntity) {
        entity = parseEntity(entity);
      }

      return entity;
    } catch (error) {
      if (!allowSoftFail) {
        handleApiError(error);
      }
    }
  }

  async baseApiPostEntity(args: {
    url: string;
    entity: any;
    include?: string;
    parseEntity?: (entity: any) => any;
  }) {
    const { url, entity, include, parseEntity } = args;

    const postUrl = url + (include ? `?include=${include}` : "");

    try {
      const request = ApiConverter.toRequestData(entity, new FormData());
      const response = await axios.post(postUrl, request, {
        headers: authHeader(),
        signal: getAbortController(this.apiIdentifier).signal,
      });

      let createdEntity = response.data.data;
      if (parseEntity) {
        createdEntity = parseEntity(createdEntity);
      }

      return createdEntity;
    } catch (error) {
      handleApiError(error);
    }
  }

  async baseApiPutEntity(args: {
    url: string;
    entity: any;
    include?: string;
    parseEntity: (entity: any) => any;
    containsFile?: boolean;
  }) {
    const { url, entity, include, parseEntity, containsFile = false } = args;

    const id = entity.id;
    delete entity.id;
    const putUrl = `${url}/${id}` + (include ? `?include=${include}` : "");

    try {
      const headers = authHeader();
      let response = null;
      if (containsFile) {
        headers["Content-Type"] = "multipart/form-data";
        const request = ApiConverter.toRequestData(entity, new FormData());
        const joinerString = putUrl.includes("?") ? "&" : "?";

        response = await axios.post(
          `${putUrl}${joinerString}_method=PUT`,
          request,
          {
            headers,
            signal: getAbortController(this.apiIdentifier).signal,
          }
        );
      } else {
        const request = ApiConverter.toRequestData(
          entity,
          new URLSearchParams()
        );
        response = await axios.put(putUrl, request, {
          headers,
          signal: getAbortController(this.apiIdentifier).signal,
        });
      }

      let updatedEntity = response.data.data;
      if (parseEntity) {
        updatedEntity = parseEntity(updatedEntity);
      }

      return updatedEntity;
    } catch (error) {
      handleApiError(error);
    }
  }

  async baseApiPatchEntity(args: {
    url: string;
    entity: any;
    include?: string;
    parseEntity?: (entity: any) => any;
  }) {
    const { url, entity, include, parseEntity } = args;
    const { id, ...extraEntityFields } = entity;

    const idParamsMapping = Object.keys(extraEntityFields)
      .map((key) => `${key}=${entity[key]}`)
      .join("&");

    const patchUrl =
      `${url}/${id}` +
      (idParamsMapping ? `?${idParamsMapping}` : "") +
      (include
        ? idParamsMapping
          ? `&include=${include}`
          : `?include=${include}`
        : "");

    try {
      // const request = ApiConverter.toRequestData(entity, new FormData());
      const response = await axios.patch(
        patchUrl,
        {},
        {
          headers: authHeader(),
          signal: getAbortController(this.apiIdentifier).signal,
        }
      );

      let updatedEntity = response.data.data;
      if (parseEntity) {
        updatedEntity = parseEntity(updatedEntity);
      }

      return updatedEntity;
    } catch (error) {
      handleApiError(error);
    }
  }

  async baseApiDeleteEntity(args: {
    url: string;
    entityId: string;
    parseEntity?: (entity: any) => any;
  }) {
    const { url, entityId, parseEntity } = args;
    const deleteUrl = url + `/${entityId}`;

    try {
      const response = await axios.delete(deleteUrl, {
        headers: authHeader(),
        signal: getAbortController(this.apiIdentifier).signal,
      });

      let deletedEntity = response.data.data;
      if (parseEntity && deletedEntity) {
        deletedEntity = parseEntity(deletedEntity);
      }

      return deletedEntity;
    } catch (error) {
      handleApiError(error);
    }
  }

  async baseApiRestoreEntity(args: {
    url: string;
    entityId: string;
    parseEntity?: (entity: any) => any;
  }) {
    const { url, entityId, parseEntity } = args;
    const restoreUrl = url + `/restore/${entityId}`;

    try {
      const response = await axios.post(restoreUrl, undefined, {
        headers: authHeader(),
        signal: getAbortController(this.apiIdentifier).signal,
      });

      let restoredEntity = response.data.data;
      if (parseEntity && restoredEntity) {
        restoredEntity = parseEntity(restoredEntity);
      }

      return restoredEntity;
    } catch (error) {
      handleApiError(error);
    }
  }

  async baseApiDownloadFile(args: {
    url: string;
    entityId: string;
    params?: any;
  }) {
    const { url, entityId, params } = args;
    const downloadUrl = url + `/${entityId}/download`;

    try {
      const headers = authHeader();

      const response = await axios.get(downloadUrl, {
        params,
        headers,
        responseType: "arraybuffer",
        signal: getAbortController(this.apiIdentifier).signal,
      });

      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async baseApiAddTag(args: {
    url: string;
    entityId: string;
    tag: string;
    parseEntity: (entity: any) => any;
  }) {
    const { url, entityId, tag, parseEntity } = args;

    const putUrl = `${url}/${entityId}/tag/${tag}?include=tag_ids`;

    try {
      const response = await axios.put(putUrl, null, {
        headers: authHeader(),
        signal: getAbortController(this.apiIdentifier).signal,
      });

      let updatedEntity = response.data.data;
      if (parseEntity) {
        updatedEntity = parseEntity(updatedEntity);
      }

      return updatedEntity;
    } catch (error) {
      handleApiError(error);
    }
  }

  async baseApiRemoveTag(args: {
    url: string;
    entityId: string;
    tag: string;
    parseEntity: (entity: any) => any;
  }) {
    const { url, entityId, tag, parseEntity } = args;
    const deleteUrl = `${url}/${entityId}/tag/${tag}?include=tag_ids`;

    try {
      const response = await axios.delete(deleteUrl, {
        headers: authHeader(),
        signal: getAbortController(this.apiIdentifier).signal,
      });

      let updatedEntity = response.data.data;
      if (parseEntity) {
        updatedEntity = parseEntity(updatedEntity);
      }

      return updatedEntity;
    } catch (error) {
      handleApiError(error);
    }
  }
}
