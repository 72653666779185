import { useOrganizationTaskApi } from "@/api/organization/OrganizationTaskApi";
import { getLangMapItem } from "@/composables/frontend/GetLangMapItem";
import type { OrganizationTask } from "@/interfaces/organization/task/OrganizationTask";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { useUserStore } from "@/stores/user/User";
import { defineStore } from "pinia";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationTasksStore = defineStore(
  "organizationTasks",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationTask>();
    const pageCache = new OrganizationCache<OrganizationTask>();

    const genericActions = new OrganizationGenericActions<OrganizationTask>({
      storage: storage,
      pageCache: pageCache,
      entityApi: useOrganizationTaskApi(),
      enhanceEntity: enhanceEntity,
    });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationTask,
  storage: OrganizationEntityStorage<OrganizationTask>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const organizationUsersStore = useOrganizationUsersStore();
  addComputedField<OrganizationTask, OrganizationUser>(
    entity,
    "assignedBy",
    () => storage.get(entityIdentifier)?.assigned_by_organization_user_id,
    async () =>
      organizationUsersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.assigned_by_organization_user_id,
      })
  );
  addComputedField<OrganizationTask, OrganizationUser>(
    entity,
    "assignedTo",
    () => storage.get(entityIdentifier)?.assigned_to_organization_user_id,
    async () =>
      organizationUsersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.assigned_to_organization_user_id,
      })
  );
  addComputedField<OrganizationTask, OrganizationUser>(
    entity,
    "completedBy",
    () => storage.get(entityIdentifier)?.completed_by_organization_user_id,
    async () =>
      organizationUsersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.completed_by_organization_user_id,
      })
  );

  const userStore = useUserStore();
  setEntityNameFields(entity, () => {
    const langMap = storage.get(entityIdentifier)?.title_lang_map;
    const langs = Object.keys(langMap || {});

    if (!langMap || langs.length == 0) {
      return storage.get(entityIdentifier)?.id;
    }

    if (langs.length > 1) {
      if (getLangMapItem(storage.get(entityIdentifier)?.title_lang_map)) {
        return getLangMapItem(storage.get(entityIdentifier)?.title_lang_map);
      }
    }

    return langMap[langs[0]];
  });
}
