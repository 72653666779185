<template>
  <div
    class="global-search"
    :class="{
      'global-search--focused': isFocus,
      'global-search--loading': loading,
      'global-search--has-value': searchResults.length || searchQuery
    }"
    tabindex="0"
    @focus="inputFocusToggle(true)"
    @blur="inputFocusToggle(false)"
  >
    <template v-if="activeOrganization">
      <Search
        :displayIcon="true"
        :displayFullWidth="true"
        :searchQuery="searchQuery"
        autocomplete="off"
        id="global-search-input-wrapper"
        @clearSearch="clearSearch"
        @changeSearchQuery="changeSearchQuery"
        @loading="setLoading"
        @isFocused="inputFocusToggle"
      />
      <div
        class="global-search__close-mobile"
        @click="emit('closeSearch')"
      ></div>
      <div
        class="global-search__results"
        :class="{
          'global-search__results--visible': showDropdown,
        }"
      >
        <div v-if="loading" class="global-search__results__loading">
          <LoaderMiniSpinner :size="24" :width="4" />
        </div>
        <ul
          class="global-search__results__list"
          v-else-if="searchResults.length"
        >
          <li
            class="global-search__results__list__item"
            v-for="item in searchResults"
            :key="item.id"
          >
            <div class="global-search__search-result">
              <RouterLink
                class="global-search__search-result__link"
                :to="getSearchRoute(item.entity, item.id)"
              ></RouterLink>
              <Icon
                class="global-search__search-result__icon"
                :width="16"
                :height="16"
                :slug="determinateIcon(item)"
              />
              <div class="global-search__search-result__middle">
                <div class="global-search__search-result__title">
                  {{ item.title }}
                </div>
                <div
                  class="global-search__search-result__details"
                  v-if="item.meta"
                >
                  <span v-for="meta in Object.keys(item.meta)">
                    <template v-if="item.meta[meta]">
                      <span
                        class="global-search__search-result__details__label"
                      >
                        {{
                          frontend.trans(
                            `global_search.meta.${meta}`,
                          )
                        }}
                        :
                      </span>
                      {{ item.meta[meta] }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="global-search__search-result__view">
                <span class="global-search__search-result__relevance">
                  <span class="global-search__search-result__relevance__label">
                    {{
                      frontend.trans(
                        "global_search.relevance",
                      )
                    }}
                    -
                  </span>
                  {{
                    parseFloat(
                      (item.ranking_score * 100).toString()
                    ).toFixed(0)
                  }}
                  %
                </span>
                <div class="global-search__search-result__view__arrow">
                  <Icon :width="16" :height="16" slug="chevron-right" />
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div
          v-else-if="!searchResults.length && !loading && searchQuery"
          class="global-search__no-results"
        >
          <Icon :width="24" :height="24" slug="alert-circle" />
          {{ frontend.trans("global_search.no_results") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import Icon from "@/components/icons/Icon.vue";
import Search from "@/components/lists/filters/Search.vue";
import LoaderMiniSpinner from "@/components/loaders/LoaderMiniSpinner.vue";
import { getSearchRoute } from "@/composables/global-search/GetRoute";
import type { SearchEntityItem } from "@/interfaces/generic/SearchEntity";
import { replaceAll } from "@/lib/common/StringUtils";
import { useFrontendStore } from "@/stores/Frontend";
import { useOrganizationsStore } from "@/stores/organization/entity/Organizations";
import { useUserStore } from "@/stores/user/User";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const loading = ref(false);
const isFetchingData = ref(false);
const searchQuery = ref("");
const organizationsStore = useOrganizationsStore();
const frontend = useFrontendStore();
const isFocus = ref(false);
const activeOrganization = useUserStore().activeOrganization;
const searchResults = ref<any>([]);
const showDropdown = computed(() => {
  return isFocus.value && (loading.value || searchResults.value.length || (!searchResults.value.length && !loading.value && searchQuery.value))
});

const emit = defineEmits(["closeSearch"]);

watch(route, async () => {
  clearSearch()
  emit("closeSearch")
});

const fetchItems = async (search?: string) => {
  if (!activeOrganization?.id || !search) {
    searchQuery.value = "";
    searchResults.value = []

    return;
  }

  loading.value = true;
  isFetchingData.value = true

  const args = {
    organizationId: activeOrganization.id,
  } as {
    organizationId: string;
    q?: string;
  };

  if (search) {
    args.q = search;
  }

  searchResults.value = [];

  const results = await organizationsStore.globalSearch(args);

  searchResults.value = results;
  loading.value = false;
  isFocus.value = true;
  isFetchingData.value = false
}

const setLoading = (val: boolean) => {
  if (isFetchingData.value) return;
  loading.value = val;
};

const changeSearchQuery = (newSearch: string) => {
  searchQuery.value = newSearch;
  fetchItems(newSearch);
};

const clearSearch = () => {
  isFocus.value = false;
  searchQuery.value = "";
  searchResults.value = []
};

const inputFocusToggle = (val: boolean) => {
  if (val) {
    isFocus.value = true;
  } else {
    isFocus.value = false;
  }
}

const determinateIcon = (item: SearchEntityItem) => {
  let iconSlug = item.entity.replace("organization", "entity")
  iconSlug = replaceAll(iconSlug, "_", "-");

  if (iconSlug[iconSlug.length-1] === "s") {
    iconSlug = iconSlug.slice(0, iconSlug.length-1);
  }

  return iconSlug
}
</script>

<style scoped lang="scss">
.global-search {
  padding-right: var(--enlivy-spacing-lg);
  position: relative;
  width: 50%;
  max-width: 800px;
  display: flex;
  align-items: center;
  z-index: 0;
  @include transition(all 0.2s ease-in-out);
  :deep(input) {
    background-color: var(--enlivy-grey-15-color) !important;
    @include transition(all 0.2s ease-in-out);
  }
  &--focused,
  &--has-value {
    width: 100%;
    max-width: 1200px;
    :deep(input) {
      background-color: var(--enlivy-white-100-color) !important;
    }
  }
  &__close-mobile {
    cursor: pointer;
    display: none;
    position: absolute;
    z-index: 1;
    width: 65px;
    height: 50px;
    top: 9px;
    right: var(--enlivy-spacing-divider-xs);
    @include transition(all 0.3s ease);
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 2px;
      height: 24px;
      background-color: var(--enlivy-grey-75-color);
      top: 13px;
      left: 32px;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: var(--enlivy-red-100-color);
      }
    }
  }
  &__results {
    position: absolute;
    z-index: -1;
    top: 10px;
    right: calc(var(--enlivy-spacing-lg) - 4px);
    left: -4px;
    padding-top: 54px;
    border-radius: 12px;
    background-color: hsl(from var(--enlivy-white-100-color) h s calc(l + 5));
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    padding: 54px 4px 0;
    @include transition(all 0.2s ease-in-out);
    &--visible {
      top: -4px;
      opacity: 1;
      visibility: visible;
    }
    &__loading {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &__list {
      overflow-y: auto;
      max-height: 700px;
      &__item {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        &:first-child {
          border: none;
          margin-top: 4px;
        }
        &:last-child {
          margin-bottom: 4px;
        }
        &:hover {
          border-color: transparent;
          & + li {
            border-color: transparent;
          }
        }
      }
    }
  }
  &__search-result {
    display: flex;
    align-items: center;
    padding: var(--enlivy-spacing-lg);
    border-radius: 10px;
    cursor: pointer;
    gap: var(--enlivy-spacing-lg);
    position: relative;
    z-index: 0;
    &__link {
      position: absolute;
      border-radius: 10px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    &__icon {
      :deep(svg) {
        fill: var(--enlivy-grey-75-color);
      }
      :deep(path),
      :deep(g) {
        stroke: var(--enlivy-grey-75-color);
      }
    }
    &__middle {
      flex: 1;
    }
    &__title {
      @include transition(all 0.2s ease-in-out);
    }
    &__relevance {
      font-size: var(--enlivy-text-sm-font-size);
      &__label {
        opacity: 0;
        @include transition(all 0.2s ease-in-out);
      }
    }
    &__details {
      line-height: 1;
      font-size: var(--enlivy-text-sm-font-size);
      margin-top: 2px;
      display: flex;
      flex-wrap: wrap;
      gap: var(--enlivy-spacing-xs);
      &__label {
        opacity: 0.5;
      }
    }
    &__view {
      position: relative;
      @include transition(all 0.2s ease-in-out);
      &__arrow {
        display: flex;
        align-items: center;
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        @include transition(all 0.2s ease-in-out);
        :deep(svg) {
          fill: var(--enlivy-primary-color);
        }
        :deep(path),
        :deep(g) {
          stroke: var(--enlivy-primary-color);
        }
      }
    }
    &:hover {
      background-color: var(--enlivy-grey-15-color);
      .global-search {
        &__search-result {
          &__title {
            color: var(--enlivy-primary-color);
          }
          &__relevance {
            &__label {
              opacity: 0.5;
            }
          }
          &__view {
            padding-right: 25px;
            &__arrow {
              right: 0;
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
  &__no-results {
    display: flex;
    justify-content: center;
    padding: 40px;
    gap: var(--enlivy-spacing-lg);
    @include font-large;
  }
  @media only screen and (max-width: $breakpoint-landscape-tablet) {
    position: fixed;
    padding-right: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
    margin-right: 0;
    background-color: rgba(black, 0.7);
    display: block;
    .search {
      position: absolute;
      left: 9px;
      top: 9px;
      right: 75px;
      width: auto;
    }
    &__results {
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      opacity: 1;
      visibility: visible;
      &__list {
        max-height: initial;
        position: absolute;
        bottom: 5px;
        right: 5px;
        left: 5px;
        top: 54px;
      }
    }
    &__close-mobile {
      display: block;
    }
  }
}
</style>
