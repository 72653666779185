import { useOrganizationContractStatusApi } from "@/api/organization/OrganizationContractStatusApi";
import { getLangMapItem } from "@/composables/frontend/GetLangMapItem";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationContractStatus } from "@/interfaces/organization/contract/OrganizationContractStatus";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { isObject } from "@/lib/Object";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { useUserStore } from "@/stores/user/User";
import { defineStore } from "pinia";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationContractStatusesStore = defineStore(
  "organizationContractStatuses",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationContractStatus>();
    const pageCache = new OrganizationCache<OrganizationContractStatus>();
    const api = useOrganizationContractStatusApi();

    const genericActions =
      new OrganizationGenericActions<OrganizationContractStatus>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
        enhanceEntity: enhanceEntity,
      });

    const getPage = async (args: {
      entityIdentifier: EntityIdentifier;
      useCache: boolean;
      page?: number;
      limit?: number;
      deleted?: number;
      params?: { [key: string]: any };
      withoutIncludes?: boolean;
    }) => {
      const { entityIdentifier: constEntityIdentifier, ...extraArgs } = args;
      let entityIdentifier = constEntityIdentifier;

      if (!isObject(entityIdentifier)) {
        entityIdentifier = {};
      }

      if (!("organizationId" in entityIdentifier)) {
        const organization = useUserStore().getActiveOrganization();
        entityIdentifier.organizationId = organization.value?.id;
      }

      const queryParams = { entityIdentifier, ...extraArgs };
      const cacheKey = `${entityIdentifier.organizationId}.${JSON.stringify(
        queryParams
      )}`;

      const cachedResponse = pageCache.get(cacheKey);
      if (args.useCache && cachedResponse != undefined) {
        return cachedResponse;
      }

      const response = await api.get(queryParams);
      const sortedResponseEntities = Array.from(response.entities).sort(
        (a, b) => {
          return a.order - b.order;
        }
      );
      const storedEntities = genericActions.storeEntities(
        sortedResponseEntities
      );

      return pageCache.set(cacheKey, {
        entities: genericActions.getEntityRefs(storedEntities),
        pagination: response.pagination,
        navigation: response.navigation,
        navigationByState: response.navigationByState,
      });
    };

    const reorder = async (args: {
      organizationId: string;
      newOrderIds: string[];
    }) => {
      const response = await api.reorder(args);

      if (response && response?.length !== 0) {
        const sortedResponse = Array.from(response).sort((a, b) => {
          return a.order - b.order;
        });

        const storedEntities = genericActions.storeEntities(sortedResponse);

        return genericActions.getEntityRefs(storedEntities);
      }
      return [];
    };

    return {
      ...genericActions,
      getPage,
      reorder,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationContractStatus,
  storage: OrganizationEntityStorage<OrganizationContractStatus>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const userStore = useUserStore();
  const organizationUserStore = useOrganizationUsersStore();

  addComputedField<OrganizationContractStatus, OrganizationUser>(
    entity,
    "deletedByUser",
    () => storage.get(entityIdentifier)?.deleted_by_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.deleted_by_user_id,
      })
  );

  setEntityNameFields(entity, () =>
    getLangMapItem(storage.get(entityIdentifier)?.title_lang_map)
  );
}
