import packageJson from "#root/package.json";
import { useFrontendApi } from "@/api/FrontendApi";
import localLangJson from "@/data/local_lang.json";
import type { Country } from "@/interfaces/organization/Country";
import type { Currency } from "@/interfaces/organization/Currency";
import type { OrganizationBankAccountDataInstitution } from "@/interfaces/organization/bank/account-data/OrganizationBankAccountDataInstitution";
import { getObjectProperty } from "@/lib/Object";
import { isString } from "lodash";
import { defineStore } from "pinia";
import { sprintf } from "sprintf-js";

interface FrontendOption {
  name: string;
  alias: string;
  color?: string;
  description?: string;
}

export const useFrontendStore = defineStore("frontend", {
  state: () => ({
    // @TODO App version has to be retrieved from somewhere. This is a quick hack.
    appVersion: packageJson.version,
    locale: "en",
    title: {
      site: import.meta.env.VITE_APP_TITLE,
      organization: undefined as string | undefined,
      page: undefined as string | undefined,
    },
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    deviceBreakpoints: {
      /* Common device breakpoints */
      portraitTablet: 600,
      landscapeTablet: 768,
      desktop: 992,
      largeDesktop: 1200,
      xLargeDesktop: 1400,
    },
    darkMode: false,
    isLoaded: false,

    countries: [] as Country[],
    currencies: [] as Currency[],
    lang: {},
    local_lang: localLangJson as {},
    locales: {} as { [key: string]: string },
    bank_account_types: [] as FrontendOption[],
    bank_account_payment_qr_types: [] as FrontendOption[],
    bank_account_transaction: {
      states: [] as {
        name: string;
        alias: string;
        icon: string;
      }[],
    },
    bank_account_sync_connection_country_map: {} as {
      [key: string]: {
        name: string;
        iso_2: string;
        institutions: OrganizationBankAccountDataInstitution[];
      };
    },
    eu_vat_classes: [] as FrontendOption[],
    peppol_bis_billing_unit_codes: {} as {
      [key: string]: {
        code: string;
        name: string;
        description: string;
      };
    },
    peppol_vatex_codes: {} as {
      [key: string]: {
        alias: string;
        name: string;
        description: string;
      };
    },
    organization: {} as {
      invoice: {
        delivery_methods: FrontendOption[];
        invoice_statuses: FrontendOption[];
        payment_methods: FrontendOption[];
        download_types: FrontendOption[];
      };
      billing_schedules: {
        statuses: FrontendOption[];
        scheduled_payment_statuses: FrontendOption[];
        payment_methods: FrontendOption[];
        entity_types: FrontendOption[];
        frequencies: FrontendOption[];
      };
      contract: {
        categories: FrontendOption[];
        states: FrontendOption[];
        state_action_required_types: FrontendOption[];
      };
      payslip_statuses: FrontendOption[];
      payslip_schema_field_list: FrontendOption[];
      product_types: FrontendOption[];
      report_schema_types: FrontendOption[];
      report_schema_frequencies: FrontendOption[];
      report_field_list: FrontendOption[];
      webhook_events: {
        event: string;
        includes: string[];
      }[];
      network_exchange: {
        statuses: {
          name: string;
          description: string;
          alias: string;
          color: string;
        }[];
      };
    },

    countryIso2ToCountryMap: {} as { [key: string]: Country },
    countryNameToCountryMap: {} as { [key: string]: Country },
    countryIso2ToLocaleMap: {} as { [key: string]: string },
    currencyIso3ToCurrencyMap: {} as { [key: string]: Currency },

    locale_to_country_code_iso: {} as { [key: string]: string },
    currencyToLocaleMap: {
      RON: "ro",
      USD: "en-US",
      EUR: "en",
    } as { [key: string]: string },
  }),
  getters: {
    trans(state) {
      // @TODO make this function allow \n to be displayed
      return (keyword: string, has_fallback: boolean = true, ...args: any) => {
        if (
          Object.keys(state.lang).length == 0 &&
          Object.keys(state.local_lang).length == 0
        ) {
          return "";
        }

        const prop = getObjectProperty(state.local_lang, keyword);
        const prop_check_local = prop
          ? prop
          : getObjectProperty(state.lang, keyword);

        const defaultReturn: string | undefined = has_fallback
          ? keyword
          : undefined;

        return prop_check_local && isString(prop_check_local)
          ? sprintf(prop_check_local, ...args)
          : defaultReturn;
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transInfo(state) {
      return (field: string, countryCode?: string) => {
        if (countryCode == undefined) {
          return undefined;
        }

        countryCode = countryCode.toLowerCase();
        const keyword = `organization_information.${countryCode}.${field}`;
        const langTrans = this.trans(keyword);
        if (langTrans == keyword) {
          return this.trans(`organization_information.global.${field}`);
        }

        return langTrans;
      };
    },
    currency: (state) => {
      return (value: number, currency: string) => {
        if (value != undefined && currency) {
          return new Intl.NumberFormat(state.currencyToLocaleMap[currency], {
            style: "currency",
            currency: currency,
            currencyDisplay: "symbol",
          } as any).format(value);
        }
      };
    },
    number: (state) => {
      return (value: number) => {
        if (value != undefined) {
          return new Intl.NumberFormat(state.locale).format(value);
        }
      };
    },
    getPeppolBisBillingUnitCodes: (state) => {
      return () => {
        return Object.keys(state.peppol_bis_billing_unit_codes).map(
          (key) => state.peppol_bis_billing_unit_codes[key]
        );
      };
    },
    getPeppolVatexCodes: (state) => {
      return () => {
        return Object.keys(state.peppol_vatex_codes).map(
          (key) => state.peppol_vatex_codes[key]
        );
      };
    },
    getCountryByCode: (state) => {
      return (countryCode?: string): Country | undefined => {
        return countryCode
          ? state.countryIso2ToCountryMap[countryCode]
          : undefined;
      };
    },
    getCountryByName: (state) => {
      return (countryName?: string): Country | undefined => {
        return countryName
          ? state.countryNameToCountryMap[countryName]
          : undefined;
      };
    },
    getLocaleByCountryCode: (state) => {
      return (countryCode?: string): string | undefined => {
        return countryCode
          ? state.countryIso2ToLocaleMap[countryCode]
          : undefined;
      };
    },
    getCurrencyByCode: (state) => {
      return (currencyCode: string): Currency => {
        return state.currencyIso3ToCurrencyMap[currencyCode];
      };
    },
    getCountriesByBankAccountType: (state) => {
      return (type: string) => {
        const countries = [];
        for (const countryCode in state.countryIso2ToCountryMap) {
          if (
            state.countryIso2ToCountryMap[countryCode].bank_account_types[type]
          ) {
            countries.push(state.countryIso2ToCountryMap[countryCode]);
          }
        }

        return countries;
      };
    },
    getBankAccountTypesByCountry: (state) => {
      return (countryCode: string) => {
        return state.bank_account_types.filter((el: any) => {
          for (const bankAccountType in state.countryIso2ToCountryMap[
            countryCode
          ].bank_account_types) {
            if (bankAccountType == el.alias) {
              return el;
            }
          }
        });
      };
    },
    getBankAccountInformationByType: (state) => {
      return (countryCode: string, type: string) => {
        return state.countryIso2ToCountryMap[countryCode].bank_account_types[
          type
        ];
      };
    },
    getBankAccountQrTypesByCountryAndType: (state) => {
      return (countryCode: string, type: string) => {
        return (
          state.countryIso2ToCountryMap[countryCode].bank_account_types[type]
            ?.payment_qr_types || []
        );
      };
    },
    getBankAccountQrTypes: (state) => {
      return () => {
        return state.bank_account_payment_qr_types;
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCurrencySymbol: (state) => {
      return (currency: string, locale: string) => {
        const parts = new Intl.NumberFormat(locale, {
          style: "currency",
          currency,
        }).formatToParts(1);

        return parts.find((x: any) => x.type === "currency")?.value;
      };
    },
    getBankAccountDataInstitution: (state) => {
      return (countryCode: string, institutionId: string) => {
        if (
          (import.meta.env.VITE_APP_MODE == "development" ||
            import.meta.env.VITE_APP_MODE.includes("local")) &&
          institutionId == "SANDBOXFINANCE_SFIN0000"
        ) {
          return {
            id: "SANDBOXFINANCE_SFIN0000",
            name: "Sandbox Finance",
            logo: "https://cdn.nordigen.com/ais/SANDBOXFINANCE_SFIN0000.png",
          } as OrganizationBankAccountDataInstitution;
        }

        return state.bank_account_sync_connection_country_map[
          countryCode
        ].institutions.find((institution) => institution.id == institutionId);
      };
    },
  },
  actions: {
    init() {
      if (localStorage.getItem("darkMode") !== null) {
        this.darkMode = JSON.parse(localStorage.getItem("darkMode") as string);
      } else {
        this.darkMode =
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches;
      }
    },
    updateDarkMode(newValue: boolean) {
      this.darkMode = newValue;
      localStorage.setItem("darkMode", JSON.stringify(this.darkMode));
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", this.darkMode ? "#121418" : "#ffffff");
    },
    async fetchFrontend() {
      let frontend: any;
      let loadFromBackendApi: boolean = false;

      // const response = await fetch(
      //   `https://ftsm8drzvt.sharedwithexpose.com/frontend`
      // );
      // frontend = await response.json();

      try {
        frontend = await import(
          `@/data/frontend/${this.appVersion}-${this.locale}.json`
        );
        console.log(
          `Frontend locale :  : ${this.appVersion}-${this.locale}.json loaded from host server`
        );
      } catch (error) {
        console.log(
          "Error when importing the frontend configuration file from host server"
        );
        loadFromBackendApi = true;
      }

      if (loadFromBackendApi) {
        frontend = await useFrontendApi().fetchFrontend(this.locale);
        console.log(
          `Frontend locale :  : ${this.appVersion}-${this.locale} loaded from Backend server`
        );
      }

      this.$patch((state) => {
        state.countries.length = 0;
        state.countries.push(...frontend.countries);
        this.updateCountryMapping();

        state.currencies.length = 0;
        state.currencies.push(...frontend.currencies);
        this.updateCurrencyMapping();

        state.lang = frontend.lang;
        state.locales = frontend.locales;
        state.bank_account_sync_connection_country_map =
          frontend.bank_account_sync_connection_country_map;
        state.eu_vat_classes = frontend.eu_vat_classes;
        state.peppol_bis_billing_unit_codes =
          frontend.peppol_bis_billing_unit_codes;
        state.peppol_vatex_codes = frontend.peppol_vatex_codes;
        state.organization = frontend.organization;
        state.bank_account_types = frontend.bank_account_types;
        state.bank_account_transaction = frontend.bank_account_transaction;
        state.bank_account_payment_qr_types =
          frontend.bank_account_payment_qr_types;
        state.locale_to_country_code_iso = frontend.locale_to_country_code_iso;
        this.updateCountryToLocaleMapping();

        state.isLoaded = true;
      });
    },
    async updateLocale(newLocale?: string) {
      if (!newLocale || newLocale == this.locale) {
        return;
      }

      this.locale = newLocale;
      this.fetchFrontend();
    },
    updateCountryMapping() {
      for (const key in this.countryIso2ToCountryMap) {
        delete this.countryIso2ToCountryMap[key];
      }
      for (const key in this.countryNameToCountryMap) {
        delete this.countryNameToCountryMap[key];
      }

      for (const country of this.countries) {
        this.countryIso2ToCountryMap[country.iso_2] = country;
        this.countryNameToCountryMap[country.name] = country;
      }
    },
    updateCurrencyMapping() {
      for (const key in this.currencyIso3ToCurrencyMap) {
        delete this.currencyIso3ToCurrencyMap[key];
      }

      for (const currency of this.currencies) {
        this.currencyIso3ToCurrencyMap[currency.iso_3] = currency;
      }
    },
    updateCountryToLocaleMapping() {
      for (const key in this.countryIso2ToLocaleMap) {
        delete this.countryIso2ToLocaleMap[key];
      }

      this.countryIso2ToLocaleMap = Object.fromEntries(
        Object.entries(this.locale_to_country_code_iso).map(([key, value]) => [
          value,
          key,
        ])
      );
    },
    setTitlePage(page: string | undefined) {
      this.title.page = page;

      this.updateTitle();
    },
    setTitleOrganization(organization: string | undefined) {
      this.title.organization = organization;

      this.updateTitle();
    },
    updateTitle() {
      let title = this.title.site;
      if (this.title.organization) {
        title = this.title.organization + " - " + title;
      }

      if (this.title.page) {
        title = this.trans(this.title.page) + " - " + title;
      }

      document.title = title;
    },
  },
});
