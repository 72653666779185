import { useOrganizationMiscApi } from "@/api/organization/OrganizationMiscApi";
import type { Organization } from "@/interfaces/organization/Organization";
import type { CalculatedTaxTotal } from "@/interfaces/organization/misc/CalculatedTaxTotal";
import type { DeterminedTaxRate } from "@/interfaces/organization/misc/DeterminedTaxRate";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useOrganizationMiscStore = defineStore("organizationMisc", () => {
  const api = useOrganizationMiscApi();

  let timeoutId: NodeJS.Timeout | null = null;
  const promises = {} as { [key: string]: any };
  const taxTotal = ref<CalculatedTaxTotal>();

  const getTaxRateIdForTaxClass = async (
    organization: Organization,
    organization_tax_class_id: string,
    organization_receiver_user_id: string,
    country_code?: string,
    address_state?: string
  ) => {
    const response = await api.getTaxRateIdForTaxClass({
      organization,
      organization_tax_class_id: organization_tax_class_id,
      organization_receiver_user_id: organization_receiver_user_id,
      country_code: country_code,
      state: address_state,
    });

    return response as DeterminedTaxRate;
  };

  const queueGetTaxTotal = async (
    organization: Organization,
    organization_tax_rate_id: string,
    organization_receiver_user_id: string,
    amount: number
  ) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    const promise = new Promise<void>((resolve, reject) => {
      // Always keep the last resolve. If it manages to make the time, it will get executed.
      // If not, it will be never called.
      promises["getTaxTotal"] = resolve;

      timeoutId = setTimeout(async () => {
        try {
          const response = await api.getTaxTotal({
            organization,
            organization_tax_rate_id,
            organization_receiver_user_id,
            amount,
          });

          // Call resolve
          promises["getTaxTotal"]();

          // Assign the value to taxTotal
          taxTotal.value = response;
        } catch (error) {
          reject(error);
        }
      }, 500);
    });

    // When the promise is finished, the taxTotal is already updated.
    return promise;
  };

  const getTaxTotal = async (
    organization: Organization,
    organization_tax_rate_id: string,
    organization_receiver_user_id: string,
    amount: number
  ) => {
    return api.getTaxTotal({
      organization,
      organization_tax_rate_id,
      organization_receiver_user_id,
      amount,
    });
  };

  const translateText = async (args: {
    organization: Organization;
    text: string;
    from: string;
    to: string;
  }) => {
    return await api.translate({
      organization: args.organization,
      params: {
        text: args.text,
        from: args.from,
        to: args.to,
      },
    });
  };

  return { getTaxRateIdForTaxClass, getTaxTotal, translateText };
});
