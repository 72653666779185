<script setup lang="ts">
import IconClock from "@/components/icons/general/utils/IconClock.vue";
import { standardLoadingDelay } from "@/lib/GlobalVariables";
import { nextTick, ref, watch } from "vue";

// This component should be used only for search loaders
// while waiting for the user to type another key.

const props = withDefaults(defineProps<{
  continuousLoading?: boolean;
  iconColor?: string;
}>(), {
  iconColor: "var(--enlivy-primary-color)"
});

const animClass = ref(props.continuousLoading == true ? true : false);
const loaderRef = ref();

defineExpose({ resetAnimation, stopAnimation, loading: animClass });

watch(() => props.continuousLoading, (newValue) => {
  animClass.value = newValue == true ? true : false;
});

let timer: any;
function resetAnimation() {
  clearTimeout(timer);
  animClass.value = false;

  nextTick(() => {
    if (!loaderRef.value) {
      return;
    }

    // trigger a DOM reflow
    void loaderRef.value.offsetWidth;

    animClass.value = true;
    timer = setTimeout(() => {
      animClass.value = false;
    }, standardLoadingDelay);
  });
}

function stopAnimation() {
  clearTimeout(timer);
  animClass.value = false;
}
</script>

<template>
  <div ref="loaderRef" class="loader" :class="{ anim: animClass }">
    <slot>
      <IconClock class="icon" :width="20" :height="20" :color="iconColor" />
    </slot>
  </div>
</template>

<style scoped lang="scss">
.loader {
  width: 20px;
  height: 20px;
  position: relative;
  box-sizing: border-box;

  display: none;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    transform: rotate(45deg);
    position: absolute;
    box-sizing: border-box;
    inset: -3px;
    border-radius: 50%;
    border: 3px solid v-bind(iconColor);
    animation: none 1s infinite linear;
  }

  &.anim {
    display: flex;
    &::before {
      animation-name: prixClipFix;
    }
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
</style>
