export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

export function toCamelCase(
  input: string,
  keepLowerFirstLetter = false
): string {
  if (keepLowerFirstLetter) {
    return input.charAt(0) + toCamelCase(input).slice(1);
  }

  return capitalize(input.split("_").join(" ")).split(" ").join("");
}

export function findCommonPrefix(str1: string, str2: string) {
  let commonPrefix = "";
  const minLength = Math.min(str1.length, str2.length);

  for (let i = 0; i < minLength; i++) {
    if (str1[i] === str2[i]) {
      commonPrefix += str1[i];
    } else {
      break;
    }
  }

  return commonPrefix;
}

function escapeRegExp(input: string) {
  return input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function capitalize(input: string) {
  return input
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
