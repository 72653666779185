<script setup lang="ts">
import { getTooltips } from "@/composables/Tooltip";
import type { Tooltip, TooltipOrientation } from "@/interfaces/common/Tooltip";
import { useFrontendStore } from "@/stores/Frontend";
import { h, nextTick, ref } from "vue";

// This is a wrapper for all the tooltips.
// This should only be used once at the end of the body.

const tooltips = getTooltips();

const frontend = useFrontendStore();
const tooltipsWrapperInstance = ref();

const render = (tooltip: any) => {
  return h("div", { class: "tooltip" }, tooltip.tooltip());
};

function wrapperStyle(tooltip: Tooltip) {
  const style = {
    position: tooltip.type,
  } as any;

  const bodyRect = document.body.getBoundingClientRect();
  if (tooltip.attachRect) {
    style.left = tooltip.attachRect.left - bodyRect.left;
    style.top = tooltip.attachRect.top - bodyRect.top;
    style.top -= tooltip.type == "fixed" ? window.scrollY : 0;
  }

  switch (tooltip.orientation as TooltipOrientation) {
    case "top":
      style["flex-direction"] = "column-reverse";
      style["transform"] = "translateX(-50%) translateY(-100%)";
      if (tooltip.attachRect) {
        style.left += tooltip.attachRect.width / 2.0;
        style.top -= tooltip.offset;
      }
      break;
    case "right":
      style["flex-direction"] = "row";
      style["transform"] = "translateY(-50%)";
      if (tooltip.attachRect) {
        style.left += tooltip.attachRect.width + tooltip.offset;
        style.top += tooltip.attachRect.height / 2.0;
      }
      break;
    case "bottom":
      style["flex-direction"] = "column";
      style["transform"] = "translateX(-50%)";
      if (tooltip.attachRect) {
        style.left += tooltip.attachRect.width / 2.0;
        style.top += tooltip.attachRect.height + tooltip.offset;
      }
      break;
    case "bottom-left":
      style["flex-direction"] = "column";
      style["transform"] = "translateX(-75%)";
      style["align-items"] = "flex-end";
      if (tooltip.attachRect) {
        style.left += tooltip.attachRect.width / 2.0;
        style.top += tooltip.attachRect.height + tooltip.offset;
      }
      break;
    case "left":
      style["flex-direction"] = "row-reverse";
      style["transform"] = "translateX(-100%) translateY(-50%)";
      if (tooltip.attachRect) {
        style.left -= tooltip.offset;
        style.top += tooltip.attachRect.height / 2.0;
      }
      break;
  }

  style.left += "px";
  style.top += "px";

  // Flip the tooltip if it doesn't fit inside the viewport.
  if (tooltip.orientation == "right") {
    nextTick(() => {
      for (const child of tooltipsWrapperInstance.value.children) {
        if (child.id == "tooltip_" + tooltip.id) {
          if (child.getBoundingClientRect().right > frontend.windowWidth) {
            tooltip.orientation = "left";
          }
        }
      }
    });
  }
  if (tooltip.orientation == "bottom") {
    nextTick(() => {
      for (const child of tooltipsWrapperInstance.value.children) {
        if (child.id == "tooltip_" + tooltip.id) {
          if (child.getBoundingClientRect().bottom > frontend.windowHeight) {
            tooltip.orientation = "top";
          }
        }
      }
    });
  }

  return style;
}
</script>

<template>
  <div ref="tooltipsWrapperInstance" class="tooltips">
    <template v-for="(tooltip, id) in tooltips" v-bind:key="id">
      <div
        :id="'tooltip_' + id"
        :class="{
          'right'       : tooltip.orientation == 'right',
          'left'        : tooltip.orientation == 'left',
          'top'         : tooltip.orientation == 'top',
          'bottom'      : tooltip.orientation == 'bottom',
          'bottom-left' : tooltip.orientation == 'bottom-left'
        }"
        class="tooltip-wrapper"
        :style="wrapperStyle(tooltip)"
        v-show="tooltip.visible"
      >
        <render :tooltip="tooltip.content" />
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.tooltip-wrapper {
  position: fixed;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;

  &:before {
    content: '';
    position: absolute;
    margin: auto;
    width: 0;
    height: 0;
  }
  &.top {
    &:before {
      bottom: -7px;
      left: 0;
      right: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 8px solid var(--enlivy-tooltip-color);
    }
  }
  &.bottom {
    &:before {
      top: -7px;
      left: 0;
      right: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 8px solid var(--enlivy-tooltip-color);
    }
  }
  &.left {
    &:before {
      right: -7px;
      top: 0;
      bottom: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 8px solid var(--enlivy-tooltip-color);
    }
  }
  &.right {
    &:before {
      left: -7px;
      top: 0;
      bottom: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 8px solid var(--enlivy-tooltip-color);
    }
  }
  &.bottom-left {
    &:before {
      top: -7px;
      left: auto;
      right: 6px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 8px solid var(--enlivy-tooltip-color);
    }
  }
  .tooltip {
    @include font-default();
    @include border-radius( var( --enlivy-general-border-radius ) );
    padding: var(--enlivy-spacing-md) var(--enlivy-spacing-lg);
    display: flex;
    flex-direction: column;
    gap: var(--enlivy-spacing-lg);
    background: var(--enlivy-tooltip-color);

    &:deep(strong) {
      @include font-bold-large();
    }
  }
}
</style>
