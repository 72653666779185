<template>
  <div class="toggle"></div>
</template>

<style scoped lang="scss">
.toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: var(--enlivy-primary-dark-color);
  position: relative;
  @include transition(all 0.3s ease);
  &:before,
  &:after {
    content: "";
    position: absolute;
  }
  &:before {
    width: 14px;
    height: 14px;
    border: 2px solid var(--enlivy-white-100-color);;
    border-radius: 50%;
    top: 9px;
    left: 9px;
  }
  &:after {
    width: 2px;
    height: 8px;
    background-color: var(--enlivy-white-100-color);;
    top: 19px;
    left: 22px;
    transform: rotate(-45deg);
  }
}
</style>
