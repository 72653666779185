import { redirectIfMissingParams } from "@/router/RedirectBeforeEnter";
import type { RouteLocationNormalizedLoaded } from "vue-router";

export const organizationEntityRoutes = [
  {
    path: "users",
    name: "organizationUsers",
    component: () => import("@/views/organization/users/List.vue"),
    meta: {
      title: "organization_user.title.list",
      activeNav: "organization_users_list",
      acl: "users.view",
    },
  },
  {
    path: "users/new",
    name: "createOrganizationUser",
    component: () => import("@/views/organization/users/Create.vue"),
    meta: {
      title: "organization_user.title.add",
      activeNav: "organization_users_create",
      acl: "users.store",
    },
  },
  {
    path: "users/:organizationUserId",
    name: "viewOrganizationUser",
    component: () => import("@/views/organization/users/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationUserId"],
        redirectName: "organizationUsers",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_user.title.entity",
      activeNav: "organization_users_view",
      acl: "users.view",
    },
  },
  {
    path: "users/:organizationUserId/edit",
    name: "editOrganizationUser",
    component: () => import("@/views/organization/users/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationUserId"],
        redirectName: "organizationUsers",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_user.title.edit",
      activeNav: "organization_users_edit",
      acl: "users.update",
    },
  },

  {
    path: "users/:organizationUserId/addresses",
    name: "organizationUserAddresses",
    component: () => import("@/views/organization/users/addresses/List.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationUserId"],
        redirectName: "organizationUsers",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_user_address.title.list",
      activeNav: "organization_user_addresses_list",
      acl: "users.view",
    },
  },
  {
    path: "users/:organizationUserId/addresses/new",
    name: "createOrganizationUserAddress",
    component: () => import("@/views/organization/users/addresses/Create.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationUserId"],
        redirectName: "organizationUsers",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_user_address.title.add",
      activeNav: "organization_user_addresses_create",
      acl: "users.store",
    },
  },
  {
    path: "users/:organizationUserId/addresses/:organizationUserAddressId/edit",
    name: "editOrganizationUserAddress",
    component: () => import("@/views/organization/users/addresses/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationUserId", "organizationUserAddressId"],
        redirectName: "organizationUserAddresses",
        redirectParams: ["organizationId", "organizationUserId"],
      }),
    meta: {
      title: "organization_user_address.title.edit",
      activeNav: "organization_user_addresses_edit",
      acl: "users.update",
    },
  },

  {
    path: "user-roles",
    name: "organizationUserRoles",
    component: () => import("@/views/organization/users/roles/List.vue"),
    meta: {
      title: "organization_user_role.title.list",
      activeNav: "organization_user_roles_list",
      acl: "user_roles.view",
    },
  },
  {
    path: "user-roles/new",
    name: "createOrganizationUserRole",
    component: () => import("@/views/organization/users/roles/Create.vue"),
    meta: {
      title: "organization_user_role.title.add",
      activeNav: "organization_user_roles_create",
      acl: "user_roles.store",
    },
  },
  {
    path: "user-roles/:organizationUserRoleId",
    name: "viewOrganizationUserRole",
    component: () => import("@/views/organization/users/roles/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationUserRoleId"],
        redirectName: "organizationUserRoles",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_user_role.title.entity",
      activeNav: "organization_user_roles_view",
      acl: "user_roles.view",
    },
  },
  {
    path: "user-roles/:organizationUserRoleId/edit",
    name: "editOrganizationUserRole",
    component: () => import("@/views/organization/users/roles/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationUserRoleId"],
        redirectName: "organizationUserRoles",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_user_role.title.edit",
      activeNav: "organization_user_roles_edit",
      acl: "user_roles.update",
    },
  },

  {
    path: "billing-schedules",
    name: "organizationBillingSchedules",
    component: () => import("@/views/organization/billing-schedules/List.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      routeQueryParams: route.query,
    }),
    meta: {
      title: "organization_billing_schedules.title.list",
      activeNav: "organization_billing_schedules_list",
      acl: "billing_schedules.view",
    },
  },
  {
    path: "billing-schedules/new",
    name: "createOrganizationBillingSchedule",
    component: () =>
      import("@/views/organization/billing-schedules/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_billing_schedules.title.add",
      activeNav: "organization_billing_schedules_create",
      acl: "billing_schedules.store",
    },
  },
  {
    path: "billing-schedules/imports/awaiting/:organizationBillingScheduleImportId",
    name: "awaitOrganizationBillingScheduleImport",
    component: () =>
      import("@/views/organization/billing-schedules-import/Awaiting.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBillingScheduleImportId"],
        redirectName: "organizationBillingScheduleImports",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_billing_schedules_import.title.entity",
      activeNav: "organization_billing_schedules_import_await",
      acl: "billing_schedules.import",
    },
  },
  {
    path: "billing-schedules/imports/new/stripe/preview",
    name: "createOrganizationBillingScheduleImportStripeStep2",
    component: () =>
      import("@/views/organization/billing-schedules-import/StripeStep2.vue"),
    meta: {
      title: "organization_billing_schedules_import.title.import",
      activeNav: "organization_billing_schedules_import_create",
      acl: "billing_schedules.import",
    },
  },
  {
    path: "billing-schedules/imports/new/stripe",
    name: "createOrganizationBillingScheduleImportStripe",
    component: () =>
      import("@/views/organization/billing-schedules-import/Stripe.vue"),
    meta: {
      title: "organization_billing_schedules_import.title.import",
      activeNav: "organization_billing_schedules_import_create",
      acl: "billing_schedules.import",
    },
  },
  {
    path: "billing-schedules/imports",
    name: "organizationBillingScheduleImports",
    component: () =>
      import("@/views/organization/billing-schedules-import/List.vue"),
    meta: {
      title: "organization_billing_schedules_import.title.list",
      activeNav: "organization_billing_schedules_import_list",
      acl: "billing_schedules.import",
    },
  },
  {
    path: "billing-schedules/imports/:organizationBillingScheduleImportId",
    name: "viewOrganizationBillingScheduleImport",
    component: () =>
      import("@/views/organization/billing-schedules-import/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBillingScheduleImportId"],
        redirectName: "organizationBillingScheduleImports",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_billing_schedules_import.title.entity",
      activeNav: "organization_billing_schedules_import_view",
      acl: "billing_schedules.import",
    },
  },
  {
    path: "billing-schedules/imports/new",
    name: "createOrganizationBillingScheduleImport",
    component: () =>
      import("@/views/organization/billing-schedules-import/Create.vue"),
    meta: {
      title: "organization_billing_schedules_import.title.import",
      activeNav: "organization_billing_schedules_import_create",
      acl: "billing_schedules.import",
    },
  },
  {
    path: "billing-schedules/:organizationBillingScheduleId",
    name: "viewOrganizationBillingSchedule",
    component: () => import("@/views/organization/billing-schedules/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBillingScheduleId"],
        redirectName: "organizationBillingSchedules",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_billing_schedules.title.entity",
      activeNav: "organization_billing_schedules_view",
      acl: "billing_schedules.view",
    },
  },
  {
    path: "billing-schedules/:organizationBillingScheduleId/edit",
    name: "editOrganizationBillingSchedule",
    component: () => import("@/views/organization/billing-schedules/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBillingScheduleId"],
        redirectName: "organizationBillingSchedules",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_billing_schedules.title.edit",
      activeNav: "organization_billing_schedules_edit",
      acl: "billing_schedules.update",
    },
  },

  {
    path: "invoices",
    name: "organizationInvoices",
    component: () => import("@/views/organization/invoices/List.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      routeQueryParams: route.query,
    }),
    meta: {
      title: "organization_invoice.title.list",
      activeNav: "organization_invoices_list",
      acl: "invoices.view",
    },
  },
  {
    path: "invoices/new",
    name: "createOrganizationInvoice",
    component: () => import("@/views/organization/invoices/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_invoice.title.add",
      activeNav: "organization_invoices_create",
      acl: "invoices.store",
    },
  },
  {
    path: "invoices/:organizationInvoiceId",
    name: "viewOrganizationInvoice",
    component: () => import("@/views/organization/invoices/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationInvoiceId"],
        redirectName: "organizationInvoices",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_invoice.title.entity",
      activeNav: "organization_invoices_view",
      acl: "invoices.view",
    },
  },
  {
    path: "invoices/:organizationInvoiceId/edit",
    name: "editOrganizationInvoice",
    component: () => import("@/views/organization/invoices/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationInvoiceId"],
        redirectName: "organizationInvoices",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_invoice.title.edit",
      activeNav: "organization_invoices_edit",
      acl: "invoices.update",
    },
  },

  {
    path: "invoice-prefixes",
    name: "organizationInvoicePrefixes",
    component: () => import("@/views/organization/invoices/prefixes/List.vue"),
    meta: {
      title: "organization_invoice_prefix.title.list",
      activeNav: "organization_invoice_prefixes_list",
      acl: "invoice_prefixes.view",
    },
  },
  {
    path: "invoice-prefixes/new",
    name: "createOrganizationInvoicePrefix",
    component: () =>
      import("@/views/organization/invoices/prefixes/Create.vue"),
    meta: {
      title: "organization_invoice_prefix.title.add",
      activeNav: "organization_invoice_prefixes_create",
      acl: "invoice_prefixes.store",
    },
  },
  {
    path: "invoice-prefixes/:organizationInvoicePrefixId/edit",
    name: "editOrganizationInvoicePrefix",
    component: () => import("@/views/organization/invoices/prefixes/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationInvoicePrefixId"],
        redirectName: "organizationInvoicePrefixes",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_invoice_prefix.title.edit",
      activeNav: "organization_invoice_prefixes_edit",
      acl: "invoice_prefixes.update",
    },
  },

  {
    path: "invoices/network-exchanges",
    name: "organizationInvoiceNetworkExchanges",
    component: () =>
      import("@/views/organization/invoices/network-exchanges/List.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      routeQueryParams: route.query,
    }),
    meta: {
      title: "organization_invoice.network_exchange.title.list",
      activeNav: "organization_invoice_network_exchanges_list",
      acl: "invoices.view",
    },
  },
  {
    path: "invoices/network-exchanges/:organizationInvoiceNetworkExchangeId",
    name: "viewOrganizationInvoiceNetworkExchange",
    component: () =>
      import("@/views/organization/invoices/network-exchanges/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationInvoiceNetworkExchangeId"],
        redirectName: "organizationInvoiceNetworkExchanges",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_invoice.network_exchange.title.entity",
      activeNav: "organization_invoice_network_exchanges_view",
      acl: "invoices.view",
    },
  },

  {
    path: "invoices/network-exchanges/:organizationInvoiceNetworkExchangeId/new-invoice",
    name: "newInvoiceOrganizationInvoiceNetworkExchange",
    component: () =>
      import("@/views/organization/invoices/network-exchanges/NewInvoice.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationInvoiceNetworkExchangeId"],
        redirectName: "organizationInvoiceNetworkExchanges",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_invoice.network_exchange.title.new_invoice",
      activeNav: "organization_invoice_network_exchanges_new_invoice",
      acl: "invoices.store",
    },
  },

  {
    path: "receipts",
    name: "organizationReceipts",
    component: () => import("@/views/organization/receipts/List.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      routeQueryParams: route.query,
    }),
    meta: {
      title: "organization_receipt.title.list",
      activeNav: "organization_receipts_list",
      acl: "receipts.view",
    },
  },
  {
    path: "receipts/new",
    name: "createOrganizationReceipt",
    component: () => import("@/views/organization/receipts/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_receipt.title.add",
      activeNav: "organization_receipts_create",
      acl: "receipts.store",
    },
  },
  {
    path: "receipts/:organizationReceiptId",
    name: "viewOrganizationReceipt",
    component: () => import("@/views/organization/receipts/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationReceiptId"],
        redirectName: "organizationReceipts",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_receipt.title.entity",
      activeNav: "organization_receipts_view",
      acl: "receipts.view",
    },
  },
  {
    path: "receipts/:organizationReceiptId/edit",
    name: "editOrganizationReceipt",
    component: () => import("@/views/organization/receipts/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationReceiptId"],
        redirectName: "organizationReceipts",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_receipt.title.edit",
      activeNav: "organization_receipts_edit",
      acl: "receipts.update",
    },
  },

  {
    path: "tax-classes",
    name: "organizationTaxClasses",
    component: () => import("@/views/organization/tax-classes/List.vue"),
    meta: {
      title: "organization_tax_class.title.list",
      activeNav: "organization_tax_classes_list",
      acl: "tax_classes.view",
    },
  },
  {
    path: "tax-classes/new",
    name: "createOrganizationTaxClass",
    component: () => import("@/views/organization/tax-classes/Create.vue"),
    meta: {
      title: "organization_tax_class.title.add",
      activeNav: "organization_tax_classes_create",
      acl: "tax_classes.store",
    },
  },
  {
    path: "tax-classes/:organizationTaxClassId/edit",
    name: "editOrganizationTaxClass",
    component: () => import("@/views/organization/tax-classes/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationTaxClassId"],
        redirectName: "organizationTaxClasses",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_tax_class.title.edit",
      activeNav: "organization_tax_classes_edit",
      acl: "tax_classes.update",
    },
  },

  {
    path: "tax-rates",
    name: "organizationTaxRates",
    component: () => import("@/views/organization/tax-rates/List.vue"),
    meta: {
      title: "organization_tax_rate.title.list",
      activeNav: "organization_tax_rates_list",
      acl: "tax_rates.view",
    },
  },
  {
    path: "tax-rates/new",
    name: "createOrganizationTaxRate",
    component: () => import("@/views/organization/tax-rates/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_tax_rate.title.add",
      activeNav: "organization_tax_rates_create",
      acl: "tax_rates.store",
    },
  },
  {
    path: "tax-rates/:organizationTaxRateId/edit",
    name: "editOrganizationTaxRate",
    component: () => import("@/views/organization/tax-rates/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationTaxRateId"],
        redirectName: "organizationTaxRates",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_tax_rate.title.edit",
      activeNav: "organization_tax_rates_edit",
      acl: "tax_rates.update",
    },
  },

  {
    path: "bank-accounts",
    name: "organizationBankAccounts",
    component: () => import("@/views/organization/bank-accounts/List.vue"),
    meta: {
      title: "organization_bank_account.title.list",
      activeNav: "organization_bank_accounts_list",
      acl: "bank_accounts.view",
    },
  },
  {
    // @TODO
    path: "bank-accounts/setup",
    name: "setupOrganizationBankAccount",
    component: () => import("@/views/organization/bank-accounts/List.vue"),
    meta: {
      title: "organization_bank_account.title.setup",
      activeNav: "organization_bank_accounts_setup",
      acl: "bank_accounts.store",
    },
  },
  {
    path: "bank-accounts/new",
    name: "createOrganizationBankAccount",
    component: () => import("@/views/organization/bank-accounts/Create.vue"),
    meta: {
      title: "organization_bank_account.title.add",
      activeNav: "organization_bank_accounts_create",
      acl: "bank_accounts.store",
    },
  },
  {
    path: "bank-accounts/:organizationBankAccountId",
    name: "viewOrganizationBankAccount",
    component: () => import("@/views/organization/bank-accounts/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBankAccountId"],
        redirectName: "organizationBankAccounts",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_bank_account.title.entity",
      activeNav: "organization_bank_accounts_view",
      acl: "bank_accounts.view",
    },
  },
  {
    path: "bank-accounts/:organizationBankAccountId/edit",
    name: "editOrganizationBankAccount",
    component: () => import("@/views/organization/bank-accounts/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBankAccountId"],
        redirectName: "organizationBankAccounts",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_bank_account.title.edit",
      activeNav: "organization_bank_accounts_edit",
      acl: "bank_accounts.update",
    },
  },
  {
    path: "bank-account-data/link",
    name: "linkOrganizationBankAccountData",
    component: () =>
      import(
        "@/views/organization/bank-accounts/LinkOrganizationBankAccountData.vue"
      ),
    meta: {
      title: "organization_bank_account_data.title.link",
      activeNav: "organization_bank_account_data_link",
      acl: "bank_account_data",
    },
  },
  {
    path: "bank-account-data/status",
    name: "statusOrganizationBankAccountData",
    component: () =>
      import(
        "@/views/organization/bank-accounts/ListOrganizationBankAccountDataBridges.vue"
      ),
    props: (route: RouteLocationNormalizedLoaded) => ({
      routeQueryParams: route.query,
    }),
    meta: {
      title: "organization_bank_account_data.title.bridge_statuses",
      activeNav: "organization_bank_account_data_bridge_statuses",
      acl: "bank_account_data",
    },
  },

  {
    path: "bank-transactions",
    name: "organizationBankTransactions",
    component: () => import("@/views/organization/bank-transactions/List.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      routeQueryParams: route.query,
    }),
    meta: {
      title: "organization_bank_transaction.title.list",
      activeNav: "organization_bank_transactions_list",
      acl: "bank_transactions.view",
    },
  },
  {
    path: "bank-transactions/new",
    name: "createOrganizationBankTransaction",
    component: () =>
      import("@/views/organization/bank-transactions/Create.vue"),
    meta: {
      title: "organization_bank_transaction.title.add",
      activeNav: "organization_bank_transactions_create",
      acl: "bank_transactions.store",
    },
  },
  {
    path: "bank-transactions/:organizationBankTransactionId",
    name: "viewOrganizationBankTransaction",
    component: () => import("@/views/organization/bank-transactions/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBankTransactionId"],
        redirectName: "organizationBankTransactions",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_bank_transaction.title.entity",
      activeNav: "organization_bank_transactions_view",
      acl: "bank_transactions.view",
    },
  },
  {
    path: "bank-transactions/:organizationBankTransactionId/edit",
    name: "editOrganizationBankTransaction",
    component: () => import("@/views/organization/bank-transactions/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBankTransactionId"],
        redirectName: "organizationBankTransactions",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_bank_transaction.title.edit",
      activeNav: "organization_bank_transactions_edit",
      acl: "bank_transactions.update",
    },
  },

  {
    path: "bank-transactions/imports",
    name: "organizationBankTransactionImports",
    component: () =>
      import("@/views/organization/bank-transactions-import/List.vue"),
    meta: {
      title: "organization_bank_transaction_import.title.list",
      activeNav: "organization_bank_transactions_import_list",
      acl: "bank_transactions.import",
    },
  },
  {
    path: "bank-transactions/imports/new",
    name: "createOrganizationBankTransactionImport",
    component: () =>
      import("@/views/organization/bank-transactions-import/Create.vue"),
    meta: {
      title: "organization_bank_transaction_import.title.add",
      activeNav: "organization_bank_transactions_import_create",
      acl: "bank_transactions.import",
    },
  },
  {
    path: "bank-transactions/imports/new/document-boundaries",
    name: "createOrganizationBankTransactionImportStep2",
    component: () =>
      import("@/views/organization/bank-transactions-import/CreateStep2.vue"),
    meta: {
      title: "organization_bank_transaction_import.title.add",
      activeNav: "organization_bank_transactions_import_create",
      acl: "bank_transactions.import",
    },
  },
  {
    path: "bank-transactions/imports/new/import-settings",
    name: "createOrganizationBankTransactionImportStep3",
    component: () =>
      import("@/views/organization/bank-transactions-import/CreateStep3.vue"),
    meta: {
      title: "organization_bank_transaction_import.title.add",
      activeNav: "organization_bank_transactions_import_create",
      acl: "bank_transactions.import",
    },
  },
  {
    path: "bank-transactions/imports/new/preview ",
    name: "createOrganizationBankTransactionImportStep4",
    component: () =>
      import("@/views/organization/bank-transactions-import/CreateStep4.vue"),
    meta: {
      title: "organization_bank_transaction_import.title.add",
      activeNav: "organization_bank_transactions_import_create",
      acl: "bank_transactions.import",
    },
  },
  {
    path: "bank-transactions/imports/awaiting/:organizationBankTransactionImportId",
    name: "awaitOrganizationBankTransactionImport",
    component: () =>
      import("@/views/organization/bank-transactions-import/Awaiting.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBankTransactionImportId"],
        redirectName: "organizationBankTransactionImports",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_bank_transaction_import.title.entity",
      activeNav: "organization_bank_transactions_import_await",
      acl: "bank_transactions.import",
    },
  },
  {
    path: "bank-transactions/imports/:organizationBankTransactionImportId",
    name: "viewOrganizationBankTransactionImport",
    component: () =>
      import("@/views/organization/bank-transactions-import/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBankTransactionImportId"],
        redirectName: "organizationBankTransactionImports",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_bank_transaction_import.title.entity",
      activeNav: "organization_bank_transactions_import_view",
      acl: "bank_transactions.import",
    },
  },

  {
    path: "bank-transaction-cost-types",
    name: "organizationBankTransactionCostTypes",
    component: () =>
      import("@/views/organization/bank-transaction-cost-type/List.vue"),
    meta: {
      title: "organization_bank_transaction_cost_type.title.list",
      activeNav: "organization_bank_transaction_cost_types_list",
      acl: "bank_transaction_cost_types.view",
    },
  },
  {
    path: "bank-transaction-cost-type/new",
    name: "createOrganizationBankTransactionCostType",
    component: () =>
      import("@/views/organization/bank-transaction-cost-type/Create.vue"),
    meta: {
      title: "organization_bank_transaction_cost_type.title.add",
      activeNav: "organization_bank_transaction_cost_types_create",
      acl: "bank_transaction_cost_types.store",
    },
  },
  {
    path: "bank-transaction-cost-type/:organizationBankTransactionCostTypeId/edit",
    name: "editOrganizationBankTransactionCostType",
    component: () =>
      import("@/views/organization/bank-transaction-cost-type/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationBankTransactionCostTypeId"],
        redirectName: "organizationBankTransactionCostTypes",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_bank_transaction_cost_type.title.edit",
      activeNav: "organization_bank_transaction_cost_types_edit",
      acl: "bank_transaction_cost_types.update",
    },
  },

  {
    path: "products",
    name: "organizationProducts",
    component: () => import("@/views/organization/products/List.vue"),
    meta: {
      title: "organization_product.title.list",
      activeNav: "organization_products_list",
      acl: "products.view",
    },
  },
  {
    path: "products/new",
    name: "createOrganizationProduct",
    component: () => import("@/views/organization/products/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_product.title.add",
      activeNav: "organization_products_create",
      acl: "products.store",
    },
  },
  {
    path: "products/:organizationProductId",
    name: "viewOrganizationProduct",
    component: () => import("@/views/organization/products/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationProductId"],
        redirectName: "organizationProducts",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_product.title.entity",
      activeNav: "organization_products_view",
      acl: "products.view",
    },
  },
  {
    path: "products/:organizationProductId/edit",
    name: "editOrganizationProduct",
    component: () => import("@/views/organization/products/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationProductId"],
        redirectName: "organizationProducts",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_product.title.edit",
      activeNav: "organization_products_edit",
      acl: "products.update",
    },
  },

  {
    path: "tasks",
    name: "organizationTasks",
    component: () => import("@/views/organization/tasks/List.vue"),
    meta: {
      title: "organization_task.title.list",
      activeNav: "organization_tasks_list",
      acl: "tasks.view",
    },
  },
  {
    path: "tasks/new",
    name: "createOrganizationTask",
    component: () => import("@/views/organization/tasks/Create.vue"),
    meta: {
      title: "organization_task.title.add",
      activeNav: "organization_tasks_create",
      acl: "tasks.store",
    },
  },
  {
    path: "tasks/:organizationTaskId",
    name: "viewOrganizationTask",
    component: () => import("@/views/organization/tasks/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationTaskId"],
        redirectName: "organizationTasks",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_task.title.entity",
      activeNav: "organization_tasks_view",
      acl: "tasks.view",
    },
  },
  {
    path: "tasks/:organizationTaskId/edit",
    name: "editOrganizationTask",
    component: () => import("@/views/organization/tasks/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationTaskId"],
        redirectName: "organizationTasks",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_task.title.edit",
      activeNav: "organization_tasks_edit",
      acl: "tasks.update",
    },
  },

  {
    path: "tags",
    name: "organizationTags",
    component: () => import("@/views/organization/tags/List.vue"),
    meta: {
      title: "organization_tag.title.list",
      activeNav: "organization_tags_list",
      acl: "tags.view",
    },
  },
  {
    path: "tags/new",
    name: "createOrganizationTag",
    component: () => import("@/views/organization/tags/Create.vue"),
    meta: {
      title: "organization_tag.title.add",
      activeNav: "organization_tags_create",
      acl: "tags.store",
    },
  },
  {
    path: "tags/:organizationTagId/edit",
    name: "editOrganizationTag",
    component: () => import("@/views/organization/tags/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationTagId"],
        redirectName: "organizationTags",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_tag.title.edit",
      activeNav: "organization_tags_edit",
      acl: "tags.update",
    },
  },

  {
    path: "projects",
    name: "organizationProjects",
    component: () => import("@/views/organization/projects/List.vue"),
    meta: {
      title: "organization_project.title.list",
      activeNav: "organization_projects_list",
      acl: "projects.view",
    },
  },
  {
    path: "projects/new",
    name: "createOrganizationProject",
    component: () => import("@/views/organization/projects/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_project.title.add",
      activeNav: "organization_projects_create",
      acl: "projects.store",
    },
  },
  {
    path: "projects/:organizationProjectId",
    name: "viewOrganizationProject",
    component: () => import("@/views/organization/projects/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationProjectId"],
        redirectName: "organizationProjects",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_project.title.entity",
      activeNav: "organization_projects_view",
      acl: "projects.view",
    },
  },
  {
    path: "projects/:organizationProjectId/edit",
    name: "editOrganizationProject",
    component: () => import("@/views/organization/projects/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationProjectId"],
        redirectName: "organizationProjects",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_project.title.edit",
      activeNav: "organization_projects_edit",
      acl: "projects.update",
    },
  },

  {
    path: "files",
    name: "organizationFiles",
    component: () => import("@/views/organization/files/List.vue"),
    meta: {
      title: "organization_file.title.list",
      activeNav: "organization_files_list",
      acl: "files.view",
    },
  },
  {
    path: "files/new",
    name: "createOrganizationFile",
    component: () => import("@/views/organization/files/Create.vue"),
    meta: {
      title: "organization_file.title.add",
      activeNav: "organization_files_create",
      acl: "files.store",
    },
  },
  {
    path: "files/:organizationFileId/edit",
    name: "editOrganizationFile",
    component: () => import("@/views/organization/files/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationFileId"],
        redirectName: "organizationFiles",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_file.title.edit",
      activeNav: "organization_files_edit",
      acl: "files.update",
    },
  },

  {
    path: "reports",
    name: "organizationReports",
    component: () => import("@/views/organization/reports/List.vue"),
    meta: {
      title: "organization_report.title.list",
      activeNav: "organization_reports_list",
      acl: "reports.view",
    },
  },
  {
    path: "reports/new",
    name: "createOrganizationReport",
    component: () => import("@/views/organization/reports/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_report.title.add",
      activeNav: "organization_reports_create",
      acl: "reports.store",
    },
  },
  {
    path: "reports/:organizationReportId/edit",
    name: "editOrganizationReport",
    component: () => import("@/views/organization/reports/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationReportId"],
        redirectName: "organizationReports",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_report.title.edit",
      activeNav: "organization_reports_edit",
      acl: "reports.update",
    },
  },

  {
    path: "report-schemas",
    name: "organizationReportSchemas",
    component: () => import("@/views/organization/reports/schemas/List.vue"),
    meta: {
      title: "organization_report_schema.title.list",
      activeNav: "organization_report_schemas_list",
      acl: "report_schemas.view",
    },
  },
  {
    path: "report-schemas/new",
    name: "createOrganizationReportSchema",
    component: () => import("@/views/organization/reports/schemas/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_report_schema.title.add",
      activeNav: "organization_report_schemas_create",
      acl: "report_schemas.store",
    },
  },
  {
    path: "report-types/:organizationReportSchemaId/edit",
    name: "editOrganizationReportSchema",
    component: () => import("@/views/organization/reports/schemas/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationReportSchemaId"],
        redirectName: "organizationReportSchemas",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_report_schema.title.edit",
      activeNav: "organization_report_schemas_edit",
      acl: "report_schemas.update",
    },
  },

  {
    path: "payslips",
    name: "organizationPayslips",
    component: () => import("@/views/organization/payslips/List.vue"),
    meta: {
      title: "organization_payslip.title.list",
      activeNav: "organization_payslips_list",
      acl: "payslips.view",
    },
  },
  {
    path: "payslips/new",
    name: "createOrganizationPayslip",
    component: () => import("@/views/organization/payslips/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_payslip.title.add",
      activeNav: "organization_payslips_create",
      acl: "payslips.store",
    },
  },
  {
    path: "payslips/:organizationPayslipId/edit",
    name: "editOrganizationPayslip",
    component: () => import("@/views/organization/payslips/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationPayslipId"],
        redirectName: "organizationPayslips",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_payslip.title.edit",
      activeNav: "organization_payslips_edit",
      acl: "payslips.update",
    },
  },

  {
    path: "payslip-schemas",
    name: "organizationPayslipSchemas",
    component: () => import("@/views/organization/payslips/schemas/List.vue"),
    meta: {
      title: "organization_payslip_schema.title.list",
      activeNav: "organization_payslip_schemas_list",
      acl: "payslip_schemas.view",
    },
  },
  {
    path: "payslip-schemas/new",
    name: "createOrganizationPayslipSchema",
    component: () => import("@/views/organization/payslips/schemas/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_payslip_schema.title.add",
      activeNav: "organization_payslip_schemas_create",
      acl: "payslip_schemas.store",
    },
  },
  {
    path: "payslip-schemas/:organizationPayslipSchemaId/edit",
    name: "editOrganizationPayslipSchema",
    component: () => import("@/views/organization/payslips/schemas/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationPayslipSchemaId"],
        redirectName: "organizationPayslipSchemas",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_payslip_schema.title.edit",
      activeNav: "organization_payslip_schemas_edit",
      acl: "payslip_schemas.update",
    },
  },

  {
    path: "webhooks",
    name: "organizationWebhooks",
    component: () => import("@/views/organization/webhooks/List.vue"),
    meta: {
      title: "organization_webhook.title.list",
      activeNav: "organization_webhooks_list",
      acl: "webhooks",
    },
  },
  {
    path: "webhooks/new",
    name: "createOrganizationWebhook",
    component: () => import("@/views/organization/webhooks/Create.vue"),
    meta: {
      title: "organization_webhook.title.add",
      activeNav: "organization_webhooks_create",
      acl: "webhooks",
    },
  },
  {
    path: "webhooks/:organizationWebhookId/",
    name: "viewOrganizationWebhook",
    component: () => import("@/views/organization/webhooks/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationWebhookId"],
        redirectName: "organizationWebhooks",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_webhook.title.entity",
      activeNav: "organization_webhooks_view",
      acl: "webhooks",
    },
  },
  {
    path: "webhooks/:organizationWebhookId/edit",
    name: "editOrganizationWebhook",
    component: () => import("@/views/organization/webhooks/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationWebhookId"],
        redirectName: "organizationWebhooks",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_webhook.title.edit",
      activeNav: "organization_webhooks_edit",
      acl: "webhooks",
    },
  },

  {
    path: "contracts",
    name: "organizationContracts",
    component: () => import("@/views/organization/contracts/List.vue"),
    meta: {
      title: "organization_contract.title.list",
      activeNav: "organization_contracts_list",
      acl: "contracts.view",
    },
  },
  {
    path: "contracts/new",
    name: "createOrganizationContract",
    component: () => import("@/views/organization/contracts/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_contract.title.add",
      activeNav: "organization_contracts_create",
      acl: "contracts.store",
    },
  },
  {
    path: "contracts/:organizationContractId",
    name: "viewOrganizationContract",
    component: () => import("@/views/organization/contracts/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationContractId"],
        redirectName: "organizationContracts",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_contract.title.entity",
      activeNav: "organization_contracts_view",
      acl: "contracts.view",
    },
  },
  {
    path: "contracts/:organizationContractId/edit",
    name: "editOrganizationContract",
    component: () => import("@/views/organization/contracts/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationContractId"],
        redirectName: "organizationContracts",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_contract.title.edit",
      activeNav: "organization_contracts_edit",
      acl: "contracts.update",
    },
  },

  {
    path: "contract-statuses",
    name: "organizationContractStatuses",
    component: () => import("@/views/organization/contracts/statuses/List.vue"),
    meta: {
      title: "organization_contract_status.title.list",
      activeNav: "organization_contract_statuses_list",
      acl: "contract_statuses",
    },
  },
  {
    path: "contract-statuses/reorder",
    name: "reorderOrganizationContractStatuses",
    component: () =>
      import("@/views/organization/contracts/statuses/ListReorder.vue"),
    meta: {
      title: "organization_contract_status.title.list",
      activeNav: "organization_contract_statuses_list",
      acl: "contract_statuses",
    },
  },
  {
    path: "contract-statuses/new",
    name: "createOrganizationContractStatus",
    component: () =>
      import("@/views/organization/contracts/statuses/Create.vue"),
    meta: {
      title: "organization_contract_status.title.add",
      activeNav: "organization_contract_statuses_create",
      acl: "contract_statuses",
    },
  },
  {
    path: "contract-statuses/:organizationContractStatusId/edit",
    name: "editOrganizationContractStatus",
    component: () => import("@/views/organization/contracts/statuses/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationContractStatusId"],
        redirectName: "organizationContractStatuses",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_contract_status.title.edit",
      activeNav: "organization_contract_statuses_edit",
      acl: "contract_statuses",
    },
  },

  {
    path: "playbooks",
    name: "organizationPlaybooks",
    component: () => import("@/views/organization/playbooks/List.vue"),
    meta: {
      title: "organization_playbook.title.list",
      activeNav: "organization_playbooks_list",
      acl: "playbooks.view",
    },
  },
  {
    path: "playbooks/new",
    name: "createOrganizationPlaybook",
    component: () => import("@/views/organization/playbooks/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    meta: {
      title: "organization_playbook.title.add",
      activeNav: "organization_playbooks_create",
      acl: "playbooks.store",
    },
  },
  {
    path: "playbooks/:organizationPlaybookId",
    name: "viewOrganizationPlaybook",
    component: () => import("@/views/organization/playbooks/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationPlaybookId"],
        redirectName: "organizationPlaybooks",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_playbook.title.entity",
      activeNav: "organization_playbooks_view",
      acl: "playbooks.view",
    },
  },
  {
    path: "playbooks/:organizationPlaybookId/edit",
    name: "editOrganizationPlaybook",
    component: () => import("@/views/organization/playbooks/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationPlaybookId"],
        redirectName: "organizationPlaybooks",
        redirectParams: ["organizationId"],
      }),
    meta: {
      title: "organization_playbook.title.edit",
      activeNav: "organization_playbooks_edit",
      acl: "playbooks.update",
    },
  },
];
