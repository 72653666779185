import { useOrganizationPayslipApi } from "@/api/organization/OrganizationPayslipApi";
import { getLangMapItem } from "@/composables/frontend/GetLangMapItem";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationContract } from "@/interfaces/organization/contract/OrganizationContract";
import type { OrganizationPayslip } from "@/interfaces/organization/payslips/OrganizationPayslip";
import type { OrganizationPayslipSchema } from "@/interfaces/organization/payslips/OrganizationPayslipSchema";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { useGenericPusherUtils } from "../../generic/GenericPusherUtils";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";
import { useUserStore } from "../../user/User";
import { useOrganizationContractsStore } from "./OrganizationContracts";
import { useOrganizationPayslipSchemasStore } from "./OrganizationPayslipSchemas";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationPayslipsStore = defineStore(
  "organizationPayslips",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationPayslip>();
    const pageCache = new OrganizationCache<OrganizationPayslip>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationPayslip>(
        "payslip",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationPayslip>({
      storage: storage,
      pageCache: pageCache,
      entityApi: useOrganizationPayslipApi(),
      ignoredKeysForDeletion: [
        "_entity",
        "id",
        "organization_id",
        "information",
      ],
      enhanceEntity: enhanceEntity,
      initializationCallback: subscribeToSocket,
    });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationPayslip,
  storage: OrganizationEntityStorage<OrganizationPayslip>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const userStore = useUserStore();
  const organizationUserStore = useOrganizationUsersStore();
  const payslipSchemasStore = useOrganizationPayslipSchemasStore();
  const organizationContractsStore = useOrganizationContractsStore();

  addComputedField<OrganizationPayslip, OrganizationContract>(
    entity,
    "contract",
    () => storage.get(entityIdentifier)?.organization_contract_id,
    async () =>
      organizationContractsStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_contract_id,
      })
  );

  addComputedField<OrganizationPayslip, OrganizationUser>(
    entity,
    "receiverUser",
    () => storage.get(entityIdentifier)?.organization_receiver_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_receiver_user_id,
      })
  );
  addComputedField<OrganizationPayslip, OrganizationUser>(
    entity,
    "senderUser",
    () => storage.get(entityIdentifier)?.organization_sender_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_sender_user_id,
      })
  );
  addComputedField<OrganizationPayslip, OrganizationUser>(
    entity,
    "deletedByUser",
    () => storage.get(entityIdentifier)?.deleted_by_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.deleted_by_user_id,
      })
  );
  addComputedField<OrganizationPayslip, OrganizationPayslipSchema>(
    entity,
    "payslipSchema",
    () => storage.get(entityIdentifier)?.organization_payslip_schema_id,
    async () =>
      payslipSchemasStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_payslip_schema_id,
      })
  );

  setEntityNameFields(
    entity,
    () =>
      storage.get(entityIdentifier)?.receiverUser?.name +
      " - " +
      getLangMapItem(
        storage.get(entityIdentifier)?.payslipSchema?.name_lang_map
      )
  );
}
