<script setup lang="ts">
import IconClose from "@/components/icons/general/actions/IconClose.vue";

const emit = defineEmits(["clicked"]);
const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    size?: number;
    color?: string;
    strokeWidth?: number;
  }>(), {
    disabled: false,
    color: "var( --enlivy-grey-50-color )"
  }
);

function onClick() {
  if (props.disabled) {
    return;
  }

  emit("clicked");
}
</script>

<template>
  <button @click.prevent="onClick" :class="{ disabled: disabled }">
    <IconClose
      :width="size"
      :height="size"
      :color="color"
      :stroke-width="strokeWidth"
    />
  </button>
</template>

<style scoped lang="scss">
button {
  display: flex;
  min-height: auto;

  &:deep(svg) {
      @include svg-color( v-bind(color) );
  }

  &:hover {
    &:deep(svg) {
      @include svg-color(var( --enlivy-status-red-color ));
    }
  }
}
</style>
